/* Styles import */
import styles from "../subHeader/subHeader.module.css";

/* Global import */
import React from "react";
import { Link, Route } from "react-router-dom";
import i18n from "i18next";
import ReportingDGA from "../../pages/ReportingDGA";
import {
  Title,
  Menu,
  MenuItem,
  Notification,
} from "../horizon-components-react/src/components";
/* Custom import */
import SubHeader from "../subHeader";

interface Props {
  pages: Pages;

  rootStore: import("../../stores/rootStore").default;
}

type Pages = "REPORTING" | "FLOWS" | "LOG";

const headerReportComponent = ({ pages, rootStore }: Props) => {
  function handleClick() {
    return <Route path="/ReportingDGA" element={ReportingDGA} />;
  }

  return (
    <SubHeader>
      {rootStore.userStore.isAuthorized([
        "ADMINISTRATOR_GROUP",
        "L_A",
        "SUPER_ADMINISTRATOR",
        "L_R_A",
      ]) && (
        <div className={styles.linkContent}>
          <Link
            id="admin-tenants"
            className={`${styles.link} ${
              pages === "REPORTING" ? styles.selected : {}
            }`}
            to="/admin/report"
          >
            Reporting
          </Link>

          <Link
            id="admin-countries"
            className={`${styles.link} ${
              pages === "FLOWS" ? styles.selected : {}
            }`}
            to="/search-flowArchive"
          >
            Flows
          </Link>

          {rootStore.userStore.isAuthorized(["SUPER_ADMINISTRATOR"]) && (
            <Link
              id="admin-upload"
              className={`${styles.link} ${
                pages === "LOG" ? styles.selected : {}
              }`}
              to="/admin/log"
            >
              Log
            </Link>
          )}

          {rootStore.userStore.isAuthorized([
            "ADMINISTRATOR_GROUP",
            "L_A",
            "L_R_A",
          ]) && (
            <Link
              id="admin-upload"
              className={`${styles.link} ${
                pages === "LOG" ? styles.selected : {}
              }`}
              to="/logtenant"
            >
              Log
            </Link>
          )}
        </div>
      )}
    </SubHeader>
  );
};

export default headerReportComponent;
