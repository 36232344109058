import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import { CardHeader, Typography } from "@material-ui/core";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import {
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  handleLicenseResourcePDF,
  handleFR_DU_GLOB_PDF,
  handleFR_EU001,
  handleFR_MLPDF,
  handleFrenchPDF,
  handleNlPDF,
  pdfSubmit,
} from "./infoFromPdf"; // Assurez-vous que le chemin est correct
import { Footer } from "../../../customComponents/footer";
import { SelectCountry } from "../../../customComponents/selectCountry";
import { useParams, useNavigate } from "react-router-dom";
import {
  convertCountryIdToCode,
  convertCountryCodeToId,
  isValidNumberKey,
  generateLicenseTypeOptionsModern,
  getRegutationByCountry,
} from "../../../utils/generator";
import AppBar from "../../../components/appBar";
import HeaderLicense from "../../../components/headerLicense/headerLicense";
import { currency, OrderLine as OL, regulation } from "../../../utils/constant";
import Api from "../../../utils/api";
import { flow, toJS } from "mobx";
import {
  Delete,
  Search as Magnifier,
  Save,
  ArrowLeft,
  PictureAsPdf,
  UploadFile,
} from "@mui/icons-material";
import {
  AiOutlineEuro,
  AiOutlineDollar,
  AiOutlineCrown,
  AiOutlinePound,
} from "react-icons/ai";
import { ConfirmDialog } from "../../../customComponents/confirmDialog";
import { SnackbarMsg } from "../../../customComponents/snackbarMsg";
import { ArrowBack, Visibility } from "@material-ui/icons";
import MonetaryInput from "../../../customComponents/monetaryInput/monetaryInput";
import Destinataire from "./destinataire";
import Files from "./files";
import license from "../../../components/matching/license";
import equipments from "../../equipments";

interface Props extends InjectedProps {}
interface InjectedProps {
  rootStore: import("../../../stores/rootStore").default;
}
interface CountryType {
  id?: number;
  value: string;
  label: string;
  code?: string;
  phone?: string;
  suggested?: boolean;
}
interface licenseFile {
  b64: Blob;
  id: number;
  uniqueId: string;
  filename: string;
  path: string;
  slug: string;
  flowId: number | null;
  licenceId: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}
interface RowData {
  active_line: boolean;
  keyword: string;
  eccn_code: string;
  pn: string;
  unit_price: string;
  quantity: number;
  quantity_initial: number;
  quantity_actual: number;
  amount_initial: number;
  amount_actual: number;
  quantity_ordered: number;
  quantity_delivered: number;
  amount_ordered: number;
  amount_delivered: number;
  model: string;
}
type SnackbarProps = {
  message: string;
  severity: "success" | "error" | "info" | "warning";
  position?:
    | "top"
    | "bottom"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "center";
  size?: "small" | "medium" | "large" | "auto";
  autoHideDuration?: number;
  onClose?: () => void;
};
const EditLicense: React.FC<Props> = ({ rootStore }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const api = new Api();
  const [openModal, setOpenModal] = useState(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const [countriesDestination, setCountriesDestination] = useState<Country[]>(
    []
  );
  const [entite, setEntite] = useState<string>(
    rootStore.licenseStore.entite !== "ALL" ? rootStore.licenseStore.entite : ""
  );
  const [currentLicense, setCurrentLicense] = useState<LicenseObject>({
    documentUuid: "",
    creator_id: rootStore?.userStore?.user?.id,
    mention_cuf: "",
    cnr: 0,
    comment: "",
    company_name: "",
    end_user: "",
    created_at: "",
    cuf: 0,
    currency: "",
    customer_destination_country_id: 0,
    deleted_at: "",
    departure_country_id: 0,
    destinataire: "",
    first_recipient: "",
    destination_country_id: null,
    end_use: "CIVIL",
    end_use_comment: "",
    entite: "",
    equipments: [] as Equipment[],
    expiration_date: "",
    first_destination_country_id: 0,
    id: 0,
    intermediair_destination_country_id: 0,
    issuance_date: "",
    license_number: "",
    license_type: "",
    manufacturer: "",
    model: "",
    num_order: "",
    provisos: "",
    recipient: "",
    regulation: "",
    status: "ACTIVE",
    supplier: "",
    temporary_license: 0,
    tenant_id: rootStore.userStore.user?.tenantId as string,
    total_amount: 0,
    updated_at: "",
  } as unknown as LicenseObject);
  const leftButtons = [
    {
      id: "back",
      onClick: () => navigate(-1),
      label: i18n.t("global.previous"),
      primary: false,
      // disabled: !activeBackButton(),
      bg: "#0085ad",
      icon: ArrowLeft,
      iconPosition: "left",
    },
  ];
  const rightButtons = [
    {
      id: "save",
      onClick: () => submitSave(),
      label: i18n.t("global.save"),
      primary: true,
      // disabled: !activeSaveButton(),
      bg: "#0085ad",
      icon: Save,
      iconPosition: "left",
    },
  ];
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>({
    message: "",
    severity: "success",
    position: "top",
    size: "medium",
    autoHideDuration: 3000,
  });
  const showSnackbar = (props: SnackbarProps) => {
    setSnackbarProps({
      ...props,
      onClose: () => setOpenSnackbar(false),
    });
    setOpenSnackbar(true);
  };
  const [selectedEquipmentId, setSelectedEquipmentId] = useState<number | null>(
    null
  );
  const [indexEquipment, setIndexEquipment] = useState<number | null>(null);
  const [uploadState, setUploadState] = useState({
    uploadLicense: {} as File,
  });
  const removeEquipment = (
    equipmentId: number | null,
    index: number | null
  ) => {
    setIndexEquipment(index);
    setSelectedEquipmentId(equipmentId);
    setOpenModal(true);
  };
  async function handleConfirmDelete() {
    if (selectedEquipmentId !== null) {
      try {
        const response =
          await api.deleteLicenseEquipmentById(selectedEquipmentId);
        if (response.status === 200) {
          const newRows = rows.filter((e) => e.id !== selectedEquipmentId);
          setCurrentLicense((prevState) => ({
            ...prevState,
            equipments: prevState.equipments.filter(
              (equipment) => equipment.id !== selectedEquipmentId
            ),
          }));
          setRows(newRows);
          showSnackbar({
            message: i18n.t("errors.form.messages.equipmentSuccess"),
            severity: "success",
            position: "top-right",
            size: "auto",
            autoHideDuration: 3000,
          });
        } else {
          showSnackbar({
            message: i18n.t("errors.form.messages.equipmentFailure"),
            severity: "error",
            position: "top-right",
            size: "auto",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        showSnackbar({
          message: i18n.t("errors.form.messages.equipmentFailure"),
          severity: "error",
          position: "top-right",
          size: "auto",
          autoHideDuration: 3000,
        });
      } finally {
        setOpenModal(false);
        setSelectedEquipmentId(null);
      }
    } else {
      if (
        indexEquipment !== null &&
        indexEquipment >= 0 &&
        indexEquipment < currentLicense.equipments.length
      ) {
        setCurrentLicense((prevState) => ({
          ...prevState,
          equipments: [
            ...prevState.equipments.slice(0, indexEquipment),
            ...prevState.equipments.slice(indexEquipment + 1),
          ],
        }));
        // setRows(currentLicense.equipments);
        setOpenModal(false);
      }
    }
  }

  const handleCancelDelete = () => {
    setOpenModal(false);
    setSelectedEquipmentId(null);
  };
  const submitSave = async () => {
    // console.log(formErrors);
    // console.log(equipmentsErrors);
    if (validateEquipments() && validateForm()) {
      if (rows.length >= currentLicense.equipments.length) {
        try {
          const updatedLicense = {
            ...currentLicense,
            equipments: rows,
          };

          let res: LicenseObject;
          if (id) {
            res = await api.newPostLicense(updatedLicense, false);
          } else {
            res = await api.newPostLicense(updatedLicense, true);
          }

          showSnackbar({
            message: i18n.t("errors.form.messages.licenseSuccess"),
            severity: "success",
            position: "top-right",
            size: "auto",
            autoHideDuration: 3000,
          });

          setCurrentLicense((prevLicense) => ({
            ...prevLicense,
            id: res.id,
          }));

          navigate(`/license/${res.id}/edit`);
        } catch (error) {
          console.error("Error updating license:", error);
          showSnackbar({
            message: i18n.t("errors.form.messages.licenseFailure"),
            severity: "error",
            position: "top-right",
            size: "auto",
            autoHideDuration: 3000,
          });
        }
      }
    } else {
      showSnackbar({
        message: i18n.t("errors.form.messages.missing"),
        severity: "error",
        position: "top-right",
        size: "auto",
        autoHideDuration: 3000,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await rootStore.countriesStore.getCountries();
      await rootStore.countriesStore.setCountryTable();
      rootStore.countriesStore.getCountriesDeparture();
      try {
        if (countries.length === 0) {
          const countriesResponse = await axios.get(
            `${process.env.REACT_APP_SCREEN}/countries_departure`
          );
          await rootStore.countriesStore.getCountries();
          setCountries(countriesResponse.data);
          setCountriesDestination(toJS(rootStore.countriesStore.countryTable));
        }
        const licenseId = id;
        if (licenseId) {
          await getLicense(parseInt(licenseId));
        } else {
          setCurrentLicense({
            first_recipient: "",
            documentUuid: "",
            creator_id: rootStore?.userStore?.user?.id as number,
            mention_cuf: "",
            cnr: 0,
            comment: "",
            company_name: "",
            created_at: "",
            cuf: 0,
            currency: "",
            customer_destination_country_id: 0,
            deleted_at: "",
            departure_country_id: "",
            destinataire: "",
            destination_country_id: null,
            end_user: "",
            end_use: "CIVIL",
            end_use_comment: "",
            entite: "",
            equipments: [] as Equipment[],
            expiration_date: "",
            first_destination_country_id: 0,
            id: 0,
            intermediair_destination_country_id: 0,
            issuance_date: "",
            license_number: "",
            license_type: "",
            manufacturer: "",
            model: "",
            num_order: "",
            provisos: "",
            recipient: "",
            regulation: "",
            status: "ACTIVE",
            supplier: "",
            temporary_license: 0,
            tenant_id: rootStore.userStore.user?.tenantId as string,
            total_amount: 0,
            updated_at: "",
            documents: [],
          });
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchData();
  }, []);

  const getLicense = async (lid: any) => {
    const license = await api.newGetLicenseById(lid);
    if (license) {
      if (license.end_use === null) {
        license.end_use = "CIVIL";
      }
      if (license.status === null) {
        license.status = "ACTIVE";
      }
      if (license.tenant_id === null) {
        license.tenant_id = rootStore.userStore.user?.tenantId as string;
      }
      license.license_type === "EU001_AUTOR_GENERAL"
        ? setEu001(true)
        : setEu001(false);
      setCurrentLicense(license);
      setEntite(license.entite);
      if ((license.documents?.length as number) > 0) {
        const mostRecentDocument = license.documents
          ?.filter((item: licenseFile) => item.slug === "load-licence")
          .sort(
            (a: licenseFile, b: licenseFile) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .shift() as unknown as licenseFile; // On obtient l'élément le plus récent
        setFullFile(mostRecentDocument.b64);
        setB64(mostRecentDocument.b64);
        setFileName(mostRecentDocument?.filename as string);
      }
    }
    if (license?.equipments.length > 0) {
      setRows(license.equipments);
    } else {
      setRows(initialData);
    }

    if (rootStore.countriesStore.countryTable.length <= 0) {
      setDpCountry(
        convertCountryCodeToId(
          license.departure_country_id,
          toJS(rootStore.countriesStore.countryTable)
        )
      );
      setDtCountry(
        convertCountryIdToCode(
          license.destination_country_id as number,
          toJS(rootStore.countriesStore.countryTable)
        )
      );
      setIntermediaryCountry(
        convertCountryIdToCode(
          license.intermediair_destination_country_id,
          toJS(rootStore.countriesStore.countryTable)
        )
      );
      setFirstCountry(
        convertCountryIdToCode(
          license.first_destination_country_id,
          toJS(rootStore.countriesStore.countryTable)
        )
      );
      setCustomerCountry(
        convertCountryIdToCode(
          license.customer_destination_country_id,
          toJS(rootStore.countriesStore.countryTable)
        )
      );
    }
  };

  const getLicenseTypeOptions = () => {
    switch (currentLicense.regulation) {
      case "US_DUAL_USE_LIST_EAR":
        return generateLicenseTypeOptionsModern(["INDIVIDUAL"]);
      case "US_MUNITIONS_LIST_ITAR":
        return generateLicenseTypeOptionsModern([
          "DSP5_PERMANENT",
          "DSP73_TEMPORARY",
          "WDA_WAREHOUSE_AGREEMENT",
        ]);
      case "EU_DUAL_USE_LIST":
      case "FR_ARRETE_DU_31_JUILLET_2014_HEL":
      case "FR_LIST_DOUBLE_USAGE":
        return generateLicenseTypeOptionsModern([
          "INDIVIDUAL",
          "GLOBAL",
          "EU001_AUTOR_GENERAL",
          "PERMANENT",
        ]);
      case "FR_MUNITIONS_LIST":
        return generateLicenseTypeOptionsModern([
          "INDIVIDUAL",
          "GLOBAL",
          "LGT",
          "DEROGATION",
        ]);
      case "FR_IMPORTATION":
        return generateLicenseTypeOptionsModern([
          "AIPE",
          "AIMG",
          "AITMG",
          "AGIMG",
        ]);
      case "DE_DUAL_USE_GUTER":
      case "DE_MUNITIONS_LIST":
      case "CA_GROUP_1_DU":
      case "CA_GROUP_2_ML":
        return generateLicenseTypeOptionsModern([
          "INDIVIDUAL",
          "GLOBAL",
          "COLLECTIVE",
        ]);
      case "UK_DUAL_USE_LIST":
      case "UK_MILITARY_LIST":
        return generateLicenseTypeOptionsModern([
          "GLOBAL_OGEL",
          "INDIVIDUAL_UK",
          "TEMPORARY_UK",
        ]);
      case "ES_DUAL_USE_LIST":
      case "ES_MUNITION_LIST":
      case "EU_MUNITIONS_LIST":
        if (currentLicense.departure_country_id !== "NL") {
          return generateLicenseTypeOptionsModern([
            "GLOBAL",
            "IMPORT",
            "INDIVIDUAL",
            "TEMPORARY",
          ]);
        }
        break;
      case "EU_MUNITIONS_LIST":
        if (currentLicense.departure_country_id === "NL") {
          return generateLicenseTypeOptionsModern([
            "INDIVIDUAL",
            "GLOBAL",
            "NL005_AUTOR_GENERAL",
            "NL006_AUTOR_GENERAL",
          ]);
        }
        break;
      default:
        return null;
    }
  };
  const [fileName, setFileName] = useState<string>();
  const [B64, setB64] = useState<Blob | string>();
  const [fullFile, setFullFile] = useState<any>();
  const [dpCountry, setDpCountry] = useState<string | number>();
  const [dtCountry, setDtCountry] = useState<string | number>();
  const [intermediaryCountry, setIntermediaryCountry] = useState<
    string | number
  >();
  const [customerCountry, setCustomerCountry] = useState<string | number>();
  const [firstCountry, setFirstCountry] = useState<string | number>();
  const [eu001, setEu001] = useState<boolean>();
  const [disabledByRDO, setDisabledByRDO] = useState<boolean>(
    rootStore.userStore.isAuthorized(["L_R_A"]) ? true : false
  );
  const handleChangeDpCountry = (selectedCountry: CountryType | null) => {
    setDpCountry(selectedCountry ? selectedCountry.value : undefined);
    setCurrentLicense((prevLicense) => ({
      ...prevLicense,
      departure_country_id: selectedCountry?.value as string,
    }));
  };
  const handleChangeDtCountry = (selectedCountry: CountryType | null) => {
    setDtCountry(selectedCountry ? selectedCountry.value : undefined);
    setCurrentLicense((prevLicense) => ({
      ...prevLicense,
      destination_country_id: convertCountryCodeToId(
        selectedCountry?.value as string,
        toJS(rootStore.countriesStore.countryTable)
      ),
    }));
  };
  const handleChangeIntermediaryCountry = (
    selectedCountry: CountryType | null
  ) => {
    setIntermediaryCountry(selectedCountry ? selectedCountry.value : undefined);
    setCurrentLicense((prevLicense) => ({
      ...prevLicense,
      intermediair_destination_country_id: convertCountryCodeToId(
        selectedCountry?.value as string,
        toJS(rootStore.countriesStore.countryTable)
      ),
    }));
  };
  const handleChangeCustomerCountry = (selectedCountry: CountryType | null) => {
    setCustomerCountry(selectedCountry ? selectedCountry.value : undefined);
    setCurrentLicense((prevLicense) => ({
      ...prevLicense,
      customer_destination_country_id: convertCountryCodeToId(
        selectedCountry?.value as string,
        toJS(rootStore.countriesStore.countryTable)
      ),
    }));
  };
  const handleChangeFirstCountry = (selectedCountry: CountryType | null) => {
    setFirstCountry(selectedCountry ? selectedCountry.value : undefined);
    setCurrentLicense((prevLicense) => ({
      ...prevLicense,
      first_destination_country_id: convertCountryCodeToId(
        selectedCountry?.value as string,
        toJS(rootStore.countriesStore.countryTable)
      ),
    }));
  };
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [equipmentsErrors, setEquipmentsErrors] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    if (eu001 && !id) {
      setRows(initialData);
      handleChange(0, "model", "EU001");
    }
  }, [eu001]);

  const validateForm = (): boolean => {
    let isValid = true;
    const errors = {
      departure_country: "",
      destination_country: "",
      regulation: "",
      license_type: "",
      license_number: "",
      num_order: "",
      issuance_date: "",
      expiration_date: "",
      end_use_comment: "",
      company_name: "",
    };
    if (!currentLicense.departure_country_id && !dpCountry) {
      isValid = false;
      errors.departure_country = i18n.t("errors.form.license.departureCountry");
    }
    if (
      !currentLicense.destination_country_id &&
      // currentLicense.regulation === "FR_MUNITIONS_LIST" &&
      // currentLicense.license_type === "GLOBAL" &&
      !dtCountry &&
      !eu001
    ) {
      isValid = false;
      errors.destination_country = i18n.t(
        "errors.form.license.destinationCountry"
      );
    }
    if (!currentLicense.regulation || currentLicense.regulation === "") {
      isValid = false;
      errors.regulation = i18n.t("errors.form.license.regulation");
    }
    if (!currentLicense.license_type || currentLicense.license_type === "") {
      isValid = false;
      errors.license_type = i18n.t("errors.form.license.type");
    }
    if (!currentLicense.license_number) {
      isValid = false;
      errors.license_number = i18n.t("errors.form.license.generic");
    }
    // if (
    //   !currentLicense.entite || current.License.entite.trim() === ""
    // ) {
    //   isValid = false;
    //   errors.license_number = i18n.t("errors.form.license.exporter");
    // }
    // if ( bizarre passe d'un coup en non obligatoire, demande du 01/10/2024
    //   !currentLicense.num_order &&
    //   (currentLicense.regulation === "FR_LIST_DOUBLE_USAGE" ||
    //     currentLicense.regulation === "FR_MUNITIONS_LIST") &&
    //   currentLicense.license_type != "EU001_AUTOR_GENERAL"
    // ) {
    //   isValid = false;
    //   errors.num_order = i18n.t("errors.form.license.generic");
    // }
    if (!currentLicense.issuance_date) {
      isValid = false;
      errors.issuance_date = i18n.t("errors.form.license.generic");
    }
    if (
      !currentLicense.expiration_date &&
      currentLicense.license_type != "EU001_AUTOR_GENERAL" &&
      !eu001
    ) {
      isValid = false;
      errors.expiration_date = i18n.t("errors.form.license.generic");
    }
    if (!currentLicense.end_use_comment && !eu001) {
      isValid = false;
      errors.end_use_comment = i18n.t("errors.form.license.generic");
    }
    if (
      //condition imbitable gg
      ((!(
        currentLicense.regulation === "FR_MUNITIONS_LIST" &&
        currentLicense.license_type === "GLOBAL"
      ) &&
        currentLicense.company_name?.trim() === "") ||
        !currentLicense.company_name) &&
      !eu001
      //condition imbitable gg
    ) {
      isValid = false;
      errors.company_name = i18n.t("errors.form.license.endUse");
    }
    setFormErrors(errors);
    return isValid;
  };
  const validateEquipments = (): boolean => {
    let isValid = true;
    const newErrors: { [key: string]: string } = {};

    currentLicense.equipments?.forEach((equipment, index) => {
      if (!equipment.eccn_code && !eu001) {
        isValid = false;
        newErrors[`eccn_code_${index}`] = i18n.t(
          "errors.form.flow.classementNat"
        );
      }
    });

    setEquipmentsErrors(newErrors);

    return isValid;
  };
  const basicLicenseInfosFrom = () => {
    return (
      <Card
        variant="outlined"
        sx={{ p: 2, width: "20%" /*, minHeight: "420px"*/ }}
      >
        <CardHeader title={i18n.t("pages.license.add.expeditor")} />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={3}
          sx={{ marginBottom: 3 }}
          width={"100%"}
        >
          <FormControl
            fullWidth
            required
            size="small"
            error={formErrors.departure_country !== ""}
          >
            <SelectCountry
              onChangeInherited={handleChangeDpCountry}
              selected={currentLicense.departure_country_id}
              required
              disabled={disabledByRDO}
              size="small"
              width="100%"
              countries={countries as CountryType[]}
              label={i18n.t("pages.exportability.departure-country")}
            />

            <FormHelperText
              error
              sx={{
                position: "absolute",
                bottom: "-1.5em",
                height: "1.5em",
                whiteSpace: "nowrap",
              }}
            >
              {formErrors.departure_country}
            </FormHelperText>
          </FormControl>
          <InputLabel id="exporter-label">
            {i18n.t("pages.license.add.exporter")}
          </InputLabel>
          <Select
            size="small"
            fullWidth
            labelId="exporter-label"
            value={entite || ""}
            variant="standard"
            onChange={(e) => {
              setEntite(e.target.value);
              setCurrentLicense((prevLicense) => ({
                ...prevLicense,
                entite: e.target.value,
              }));
            }}
            label={i18n.t("pages.licence.add.exporter")}
          >
            <MenuItem value="">{i18n.t("global.select")}</MenuItem>
            {rootStore.userStore.user?.entite === "ALL"
              ? rootStore.tenantStore.entities.map((entite, index) => (
                  <MenuItem key={index} value={entite.id_entite || ""}>
                    {entite.id_entite}
                  </MenuItem>
                ))
              : rootStore.tenantStore.entities
                  .filter(
                    (entite) =>
                      entite.id_entite === rootStore.userStore.user?.entite
                  )
                  .map((entite, index) => (
                    <MenuItem key={index} value={entite.id_entite || ""}>
                      {entite.id_entite}
                    </MenuItem>
                  ))}
          </Select>
          <FormHelperText
            error
            sx={{
              position: "absolute",
              bottom: "-1.5em",
              height: "1.5em",
              whiteSpace: "nowrap",
            }}
          >
            {formErrors.exporter}
          </FormHelperText>
        </Stack>
      </Card>
    );
  };
  const basicLicenseInfos = () => {
    return (
      <Card
        variant="outlined"
        sx={{ p: 2, width: "20%" /*, minHeight: "420px"*/ }}
      >
        <CardHeader title={i18n.t("pages.license.imput.title-tab")} />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          spacing={3}
          sx={{ marginBottom: 3 }}
          width={"100%"}
        >
          <FormControl fullWidth required variant="standard">
            <InputLabel>{i18n.t("pages.license.add.regulation")}</InputLabel>
            <Select
              id="regulation"
              value={currentLicense.regulation || ""}
              required
              error={!!formErrors.regulation}
              disabled={disabledByRDO}
              onChange={(e) => {
                setCurrentLicense((prevLicense) => ({
                  ...prevLicense,
                  regulation: e.target.value as LicenseRegulation,
                }));
              }}
              label={i18n.t("pages.license.add.regulation")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                  },
                },
              }}
            >
              <MenuItem value="">
                {i18n.t("pages.new-flow.select-value")}
              </MenuItem>
              {regulation
                .filter((option) =>
                  getRegutationByCountry(
                    convertCountryCodeToId(
                      currentLicense.departure_country_id,
                      toJS(rootStore.countriesStore.countryTable)
                    )
                  ).includes(option.value)
                )
                .map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText
              sx={{
                position: "absolute",
                bottom: "-1.5em",
                height: "1.5em",
                whiteSpace: "nowrap",
              }}
              error
            >
              {formErrors.regulation}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth required variant="standard">
            <InputLabel>{i18n.t("pages.license.add.license-type")}</InputLabel>
            <Select
              id="license-type"
              required={true}
              disabled={disabledByRDO}
              value={currentLicense.license_type || ""}
              onChange={(e) => {
                e.target.value === "EU001_AUTOR_GENERAL"
                  ? setEu001(true)
                  : setEu001(false);

                setCurrentLicense((prevLicense) => ({
                  ...prevLicense,
                  license_type: e.target.value as LicenseType,
                }));
              }}
              label={i18n.t("pages.license.add.license-type")}
            >
              <MenuItem value="GLOBAL">
                {i18n.t("pages.new-flow.select-value")}
              </MenuItem>
              {getLicenseTypeOptions()}
            </Select>
            <FormHelperText
              sx={{
                position: "absolute",
                bottom: "-1.5em",
                height: "1.5em",
                whiteSpace: "nowrap",
              }}
              error
            >
              {formErrors.license_type}
            </FormHelperText>
          </FormControl>
          {!eu001 && (
            <FormControl component="fieldset" variant="standard">
              <FormControlLabel
                labelPlacement="start"
                label={i18n.t("pages.license.add.temporary-licence")}
                control={
                  <Checkbox
                    id="some-checkbox"
                    disabled={disabledByRDO}
                    checked={!!currentLicense.temporary_license || false} //"!!" signifie qu'on traduit 0, 1 ou null de la DB en booléen
                    onChange={(e) => {
                      setCurrentLicense((prevLicense) => ({
                        ...prevLicense,
                        temporary_license: e.target.checked ? 1 : 0,
                      }));
                    }}
                  />
                }
              />
            </FormControl>
          )}
        </Stack>
      </Card>
    );
  };
  const basicLicenseInfosTo = () => {
    return (
      <Destinataire
        rootStore={rootStore}
        currentLicense={currentLicense}
        formErrors={formErrors}
        countriesDestination={countriesDestination}
        handleChangeCustomerCountry={handleChangeCustomerCountry}
        handleChangeFirstCountry={handleChangeFirstCountry}
        handleChangeDtCountry={handleChangeDtCountry}
        handleChangeIntermediaryCountry={handleChangeIntermediaryCountry}
        setCurrentLicense={setCurrentLicense}
      />
    );
  };
  const recapLicenseInfo = () => {
    return (
      <Card variant="outlined" sx={{ p: 2, width: "92%" }}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ marginBottom: 3 }}
          width={"100%"}
        >
          <Stack
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
            sx={{ width: "60%" }}
          >
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
              sx={{ width: "100%" }}
            >
              {" "}
              <TextField
                id="license-number"
                label={i18n.t("pages.license.add.license-number")}
                value={currentLicense.license_number || ""}
                required
                error={!!formErrors["license_number"]}
                helperText={formErrors["license_number"]}
                disabled={disabledByRDO}
                onChange={(e) =>
                  setCurrentLicense((prevLicense) => ({
                    ...prevLicense,
                    license_number: e.target.value,
                  }))
                }
                variant="standard"
                fullWidth
              />
              {(currentLicense.regulation === "FR_LIST_DOUBLE_USAGE" ||
                currentLicense.regulation === "FR_MUNITIONS_LIST") &&
                currentLicense.license_type != "EU001_AUTOR_GENERAL" && (
                  <TextField
                    id="num_order"
                    label={i18n.t("pages.license.add.num_order")}
                    value={currentLicense.num_order || ""}
                    required={false}
                    // error={!!formErrors["num_order"]}
                    // helperText={formErrors["num_order"]}
                    disabled={disabledByRDO}
                    onChange={(e) =>
                      setCurrentLicense((prevLicense) => ({
                        ...prevLicense,
                        num_order: e.target.value,
                      }))
                    }
                    fullWidth
                    variant="standard"
                  />
                )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
              sx={{ width: "100%" }}
            >
              <TextField
                id="issuance-date"
                label={i18n.t("pages.license.add.issuance-date")}
                type="date"
                value={currentLicense.issuance_date || ""}
                disabled={disabledByRDO}
                required
                error={!!formErrors["issuance_date"]}
                helperText={formErrors["issuance_date"]}
                onChange={(e) =>
                  setCurrentLicense((prevLicense) => ({
                    ...prevLicense,
                    issuance_date: e.target.value,
                  }))
                }
                variant="standard"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />{" "}
              {currentLicense.license_type != "EU001_AUTOR_GENERAL" && (
                <TextField
                  id="expiration-date"
                  label={i18n.t("pages.license.add.expiration-date")}
                  type="date"
                  error={!!formErrors["expiration_date"]}
                  helperText={formErrors["expiration_date"]}
                  value={currentLicense.expiration_date || ""}
                  disabled={disabledByRDO}
                  required
                  onChange={(e) =>
                    setCurrentLicense((prevLicense) => ({
                      ...prevLicense,
                      expiration_date: e.target.value,
                    }))
                  }
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
              sx={{ width: "100%" }}
            >
              <MonetaryInput
                id="total-amount"
                disabled={disabledByRDO}
                showCurrency={false}
                label={i18n.t("pages.license.add.total-amount")}
                value={(currentLicense.total_amount as number) || 0}
                currency={currentLicense.currency as Currency}
                onChange={(e) =>
                  setCurrentLicense((prevLicense) => ({
                    ...prevLicense,
                    totalAmount: e as number,
                  }))
                }
              />{" "}
              <FormControl fullWidth variant="standard" sx={{ height: "10px" }}>
                {/* <InputLabel>{i18n.t("pages.license.add.currency")}</InputLabel> */}
                <Select
                  value={currentLicense.currency || ""}
                  disabled={disabledByRDO}
                  onChange={(e) =>
                    setCurrentLicense((prevLicense) => ({
                      ...prevLicense,
                      currency: e.target.value,
                    }))
                  }
                  name="currency"
                  label={i18n.t("pages.license.add.currency")}
                >
                  {currency.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value || ""}
                      sx={{
                        alignItems: "center",
                        direction: "row",
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                      >
                        {option.value === "EUR" && (
                          <AiOutlineEuro fontSize={24} />
                        )}
                        {(option.value === "USD" || option.value === "CAD") && (
                          <AiOutlineDollar fontSize={24} />
                        )}
                        {option.value === "SEK" && (
                          <AiOutlineCrown fontSize={24} />
                        )}
                        {option.value === "GBP" && (
                          <AiOutlinePound fontSize={24} />
                        )}
                        <Box>{option.label}</Box>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Files rootStore={rootStore} currentLicense={currentLicense} />
        </Stack>
      </Card>
    );
  };
  const initialData = [
    {
      keyword: "",
      eccn_code: "",
      pn: "",
      unit_price: 0,
      quantity: 0,
      quantity_actual: eu001 ? "0" : "",
      quantity_initial: 0,
      amount_initial: eu001 ? "0" : "",
      amount_actual: eu001 ? "0" : "",
      quantity_ordered: eu001 ? "0" : "",
      quantity_delivered: eu001 ? "0" : "",
      amount_ordered: eu001 ? "0" : "",
      amount_delivered: eu001 ? "0" : "",
      model: eu001 ? "EU001" : "",
    },
  ];

  const [rows, setRows] = useState<Equipment[]>(initialData);

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        keyword: "",
        eccn_code: "",
        pn: "",
        unit_price: 0,
        quantity: 0,
        quantity_actual: 0,
        quantity_initial: 0,
        amount_initial: "",
        amount_actual: "",
        quantity_ordered: "",
        quantity_delivered: "",
        amount_ordered: "",
        amount_delivered: "",
        model: eu001 ? "EU001" : "",
      },
    ]);
  };

  const handleDeleteRow = (index: number, id: number) => {
    const newRows = rows.filter((_, i) => i !== index);
    if (id) {
      // setRows(newRows);
      setIndexEquipment(index);
      setSelectedEquipmentId(id);
      setOpenModal(true);
    } else {
      setRows(newRows);
      setIndexEquipment(index);
    }
  };

  const handleChange = (
    index: number,
    key: keyof RowData,
    value: string | number | boolean
  ) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        [key]: value,
      };
      return updatedRows;
    });

    setCurrentLicense((prevLicense) => ({
      ...prevLicense,
      equipments: rows
        ? rows.map((row, i) => (i === index ? { ...row, [key]: value } : row))
        : [],
    }));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0085ad",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "5px",
      // margin: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const showEquipments = () => {
    return (
      <Card variant="outlined">
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {currentLicense.regulation === "FR_MUNITIONS_LIST" && (
                  <StyledTableCell>
                    {i18n.t("pages.license.add.active_line")}
                  </StyledTableCell>
                )}
                <StyledTableCell>
                  {i18n.t("pages.license.add.douanierAdd")}
                </StyledTableCell>
                <StyledTableCell>
                  {i18n.t("pages.license.add.classification")}
                </StyledTableCell>
                <StyledTableCell>
                  {i18n.t("pages.license.add.part-number")}
                </StyledTableCell>
                <StyledTableCell sx={{ width: "50px" }}>
                  {i18n.t("pages.license.add.quantity-initial")}
                </StyledTableCell>
                {currentLicense.regulation !== "FR_MUNITIONS_LIST" && (
                  <>
                    <StyledTableCell sx={{ width: "50px" }}>
                      {i18n.t("pages.license.add.quantity-actual")}
                    </StyledTableCell>
                  </>
                )}
                {currentLicense.regulation === "FR_MUNITIONS_LIST" && (
                  <>
                    <StyledTableCell sx={{ width: "50px" }}>
                      {i18n.t("pages.license.add.quantity-ordered")}
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: "50px" }}>
                      {i18n.t("pages.license.add.quantity-delivered")}
                    </StyledTableCell>
                  </>
                )}
                <StyledTableCell>
                  {i18n.t("pages.license.add.unit-price")}
                </StyledTableCell>
                <StyledTableCell>
                  {i18n.t("pages.license.add.amount-initial")}
                </StyledTableCell>
                {currentLicense.regulation !== "FR_MUNITIONS_LIST" && (
                  <StyledTableCell>
                    {i18n.t("pages.license.add.amount-actual")}
                  </StyledTableCell>
                )}
                {currentLicense.regulation === "FR_MUNITIONS_LIST" && (
                  <>
                    <StyledTableCell>
                      {i18n.t("pages.license.add.amount-ordered")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {i18n.t("pages.license.add.amount-delivered")}
                    </StyledTableCell>
                  </>
                )}
                <StyledTableCell>
                  {i18n.t("pages.license.add.model")}
                </StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row, index) => (
                  // {currentLicense.equipments.map((row, index) => (
                  <TableRow key={index}>
                    {currentLicense.regulation === "FR_MUNITIONS_LIST" && (
                      <TableCell>
                        <FormControlLabel
                          labelPlacement="start"
                          label=""
                          control={
                            <Checkbox
                              id="activeLine"
                              checked={!!row.active_line || false} //"!!" signifie qu'on traduit 0, 1 ou null de la DB en booléen
                              onChange={(e) => {
                                handleChange(
                                  index,
                                  "active_line",
                                  e.target.checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <TextField
                        variant="standard"
                        sx={{ width: "100px" }}
                        value={row.keyword || ""}
                        onChange={(e) =>
                          handleChange(index, "keyword", e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="standard"
                        value={row.eccn_code || ""}
                        sx={{ width: "90px" }}
                        error={!!equipmentsErrors[`eccn_code_${index}`]}
                        helperText={equipmentsErrors[`eccn_code_${index}`]}
                        onChange={(e) =>
                          handleChange(index, "eccn_code", e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant="standard"
                        value={row.pn || ""}
                        onChange={(e) =>
                          handleChange(index, "pn", e.target.value)
                        }
                      />
                    </TableCell>

                    {currentLicense.regulation !== "FR_MUNITIONS_LIST" && (
                      <>
                        <TableCell>
                          <TextField
                            InputProps={{
                              onKeyDown: (e) => {
                                if (!isValidNumberKey(e.key)) {
                                  e.preventDefault();
                                }
                              },
                              sx: {
                                "& .MuiInputBase-input": { textAlign: "right" },
                              },
                            }}
                            variant="standard"
                            value={row.quantity || ""}
                            error={!!equipmentsErrors[`quantity_${index}`]}
                            helperText={equipmentsErrors[`quantity_${index}`]}
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100px",
                            }}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "quantity",
                                parseInt(e.target.value)
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            InputProps={{
                              onKeyDown: (e) => {
                                if (!isValidNumberKey(e.key)) {
                                  e.preventDefault();
                                }
                              },
                              sx: {
                                "& .MuiInputBase-input": { textAlign: "right" },
                              },
                            }}
                            variant="standard"
                            value={row.quantity_actual || 0}
                            error={
                              !!equipmentsErrors[`quantity_actual_${index}`]
                            }
                            helperText={
                              equipmentsErrors[`quantity_actual_${index}`]
                            }
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "50px !important",
                            }}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "quantity_actual",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                      </>
                    )}
                    {currentLicense.regulation === "FR_MUNITIONS_LIST" && (
                      <>
                        <TableCell>
                          <TextField
                            InputProps={{
                              onKeyDown: (e) => {
                                if (!isValidNumberKey(e.key)) {
                                  e.preventDefault();
                                }
                              },
                              sx: {
                                "& .MuiInputBase-input": { textAlign: "right" },
                              },
                            }}
                            variant="standard"
                            value={row.quantity_initial || 0}
                            error={
                              !!equipmentsErrors[`quantity_initial_${index}`]
                            }
                            helperText={
                              equipmentsErrors[`quantity_initial_${index}`]
                            }
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "100px",
                            }}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "quantity_initial",
                                parseInt(e.target.value)
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            InputProps={{
                              onKeyDown: (e) => {
                                if (!isValidNumberKey(e.key)) {
                                  e.preventDefault();
                                }
                              },
                              sx: {
                                "& .MuiInputBase-input": { textAlign: "right" },
                              },
                            }}
                            variant="standard"
                            value={row.quantity_ordered || 0}
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "60px",
                            }}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "quantity_ordered",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            InputProps={{
                              onKeyDown: (e) => {
                                if (!isValidNumberKey(e.key)) {
                                  e.preventDefault();
                                }
                              },
                              sx: {
                                "& .MuiInputBase-input": { textAlign: "right" },
                              },
                            }}
                            variant="standard"
                            value={row.quantity_delivered || 0}
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "50px !important",
                            }}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "quantity_delivered",
                                e.target.value
                              )
                            }
                          />
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <MonetaryInput
                        id="unit-price"
                        value={row.unit_price || 0}
                        sx={{ maxWidth: "130px", width: "120px" }}
                        currency={currentLicense.currency as Currency}
                        error={!!equipmentsErrors[`unit_price_${index}`]}
                        helperText={equipmentsErrors[`unit_price_${index}`]}
                        onChange={(e) =>
                          handleChange(index, "unit_price", e?.toString())
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <MonetaryInput
                        id="initial-amount"
                        sx={{ maxWidth: "130px", width: "120px" }}
                        value={row.amount_initial || 0}
                        currency={currentLicense.currency as Currency}
                        error={!!equipmentsErrors[`amount_initial_${index}`]}
                        helperText={equipmentsErrors[`amount_initial_${index}`]}
                        onChange={(e) =>
                          handleChange(index, "amount_initial", e?.toString())
                        }
                      />
                    </TableCell>
                    {currentLicense.regulation !== "FR_MUNITIONS_LIST" && (
                      <TableCell>
                        <MonetaryInput
                          id="actual-amount"
                          sx={{ maxWidth: "130px", width: "120px" }}
                          value={row.amount_actual || 0}
                          error={!!equipmentsErrors[`amount_actual_${index}`]}
                          helperText={
                            equipmentsErrors[`amount_actual_${index}`]
                          }
                          currency={currentLicense.currency as Currency}
                          onChange={(e) =>
                            handleChange(index, "amount_actual", e?.toString())
                          }
                        />
                      </TableCell>
                    )}
                    {currentLicense.regulation === "FR_MUNITIONS_LIST" && (
                      <>
                        <TableCell>
                          <MonetaryInput
                            id="ordered-amount"
                            sx={{ maxWidth: "130px", width: "120px" }}
                            value={row.amount_ordered || 0}
                            currency={currentLicense.currency as Currency}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "amount_ordered",
                                e?.toString()
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <MonetaryInput
                            id="delivered-amount"
                            sx={{ maxWidth: "130px", width: "120px" }}
                            value={row.amount_delivered || 0}
                            currency={currentLicense.currency as Currency}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "amount_delivered",
                                e?.toString()
                              )
                            }
                          />
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <Tooltip
                        title={
                          <span style={{ fontSize: "18px" }}>{row.model}</span>
                        }
                        followCursor
                      >
                        <TextField
                          value={row.model || ""}
                          onChange={(e) =>
                            handleChange(index, "model", e.target.value)
                          }
                          // multiline
                          variant="standard"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px",
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          handleDeleteRow(index, row?.id as number)
                        }
                      >
                        {!eu001 && <Delete />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="flex-end" m={2}>
            <Button onClick={handleAddRow}>
              {i18n.t("pages.new-flow.product.equipment.addLineGeneric")}
            </Button>
          </Box>
        </TableContainer>
      </Card>
    );
  };

  async function handleLicenseFile(file: React.ChangeEvent<HTMLInputElement>) {
    setB64("empty"); //onvide le b64 pour pouvoir disabled le bouton de prévisu du fichier car on a pas sa data
    setUploadState({ ...uploadState, uploadLicense: file as unknown as File });
    const res = await pdfSubmit({
      file: file.target.files?.[0] as File,
      rootStore: rootStore,
      id: currentLicense.id as number,
      equipments: currentLicense.equipments,
    });
    const url = `${process.env.REACT_APP_SCREEN}/uploadFile`;
    const tenantId = rootStore.userStore.user?.tenantId;
    const slug = i18n.t(
      "components.upload-file-input.slug.licence.load-licence"
    );
    const categorie = i18n.t("components.upload-file-input.categorie.licence");
    const params = { tenantId, slug, categorie };
    const formData = new FormData();
    formData.append("file", file.target.files?.[0] as Blob);

    const fileinfo = await axios.post(url, formData, {
      params,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    setFullFile(file.target.files?.[0]);
    setFileName(file.target.files?.[0].name);
    const identifier = res[20];
    let licenseInfos: LicenseObject;

    switch (identifier) {
      case "FR":
        licenseInfos = await handleFrenchPDF(
          res,
          rootStore,
          currentLicense.equipments
        );
        break;
      case "FR_DU_GLOB":
        licenseInfos = await handleFR_DU_GLOB_PDF(
          res,
          rootStore,
          currentLicense.equipments
        );
        break;
      case "NL":
        licenseInfos = await handleNlPDF(
          res,
          rootStore,
          currentLicense.equipments
        );
        break;
      case "FR_ML":
        licenseInfos = await handleFR_MLPDF(
          res,
          rootStore,
          currentLicense.id as number
        );
        break;
      case "FR_EU001":
        licenseInfos = await handleFR_EU001(
          res,
          rootStore,
          currentLicense.equipments
        );
        break;
      default:
        licenseInfos = await handleLicenseResourcePDF(
          res,
          rootStore,
          currentLicense.equipments
        );
    }

    setCurrentLicense((prevLicense) => ({
      ...prevLicense,
      documentUuid: fileinfo?.data.data.uniqueId,
      ...licenseInfos,
      equipments: licenseInfos.equipments as any,
      // creator_id: rootStore.userStore?.user?.id as number,
      destination_country_id: licenseInfos.destination_country_id,
    }));
    setRows(licenseInfos.equipments);
    return file;
  }

  function base64ToBlob(base64: string, mimeType: string): Blob | void {
    if (base64 !== "") {
      base64 = base64?.replace(/\s+/g, "")?.replace(/[^A-Za-z0-9+/=]/g, "");
      const padding = "=".repeat((4 - (base64.length % 4)) % 4);
      base64 += padding;
      const byteChars = atob(base64);
      const byteNumbers = new Array(byteChars.length);
      for (let i = 0; i < byteChars.length; i++) {
        byteNumbers[i] = byteChars.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mimeType });
    }
  }

  const openPdfInNewTab = (file?: any) => {
    if (fullFile && !file) {
      const fileBlob = base64ToBlob(fullFile, "application/pdf");
      if (fileBlob) {
        const fileUrl = URL.createObjectURL(fileBlob);
        window.open(fileUrl, "_blank");
        setTimeout(() => {
          URL.revokeObjectURL(fileUrl);
        }, 1000);
      }
    }
    if (file) {
      const fileBlob = base64ToBlob(fullFile, "application/pdf");
      if (fileBlob) {
        const fileUrl = URL.createObjectURL(fileBlob);
        window.open(fileUrl, "_blank");
        setTimeout(() => {
          URL.revokeObjectURL(fileUrl);
        }, 1000);
      }
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      {openSnackbar && (
        <SnackbarMsg
          message={snackbarProps.message}
          severity={snackbarProps.severity}
          position={snackbarProps.position}
          size={snackbarProps.size}
          autoHideDuration={snackbarProps.autoHideDuration}
          onClose={snackbarProps.onClose}
        />
      )}
      <AppBar rootStore={rootStore} selectedPage={"LICENSE_MNGT"} />
      {rootStore.userStore.isAuthorized(["L_R_A"]) ? (
        <HeaderLicense pages="VIEW" rootStore={rootStore} id={id} />
      ) : (
        <HeaderLicense pages="ADD" rootStore={rootStore} />
      )}

      <Stack
        direction="row"
        spacing={4}
        width={"80%"}
        sx={{ marginTop: 10, marginLeft: 10 }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h5" style={{ color: "#0085ad" }}>
            {i18n.t("pages.license.imput.view")}
          </Typography>
        </Box>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            justifyContent: "center",
            alignItems: "flex-end",
            width: "250px",
          }}
        >
          <Button
            role={undefined}
            component="label"
            variant="contained"
            disabled={disabledByRDO}
            sx={{ backgroundColor: "#0085AD", width: "100%" }}
            endIcon={fileName ? <PictureAsPdf /> : <UploadFile />}
          >
            {i18n.t("pages.license.menu.add")}
            <VisuallyHiddenInput
              type="file"
              accept="application/pdf"
              onChange={handleLicenseFile}
            />
          </Button>

          {fileName && B64 && (
            <>
              <Button
                variant="contained"
                onClick={openPdfInNewTab}
                disabled={disabledByRDO || B64 === "empty"}
                endIcon={<Visibility />}
                sx={{
                  backgroundColor: (theme) => "white",
                  color: (theme) => "#0085AD",
                  "&:hover": {
                    backgroundColor: (theme) => "#0085AD",
                    color: (theme) => "white",
                  },
                  width: "100%",
                }}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    maxWidth: "100%",
                  }}
                >
                  {fileName}
                </span>
              </Button>
            </>
          )}
        </Stack>
      </Stack>
      {/* Container de formulaire */}
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: "3em", /*minHeight: "70vh",*/ paddingBottom: "3em" }}
      >
        {/* Container de formulaire */}
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          width={"90%"}
          sx={{ marginTop: 5 }}
        >
          {basicLicenseInfosFrom()}
          {basicLicenseInfos()}
          {!eu001 && basicLicenseInfosTo()}
          <Box sx={{ marginTop: 5, marginBottom: 5 }} />

          {/* {loading ? renderSkeleton() : FlowLines()} */}
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          width={"90%"}
          sx={{ marginTop: 5 }}
        >
          {recapLicenseInfo()}
          <Box sx={{ marginTop: 5, marginBottom: 5 }} />
          {/* {loading ? renderSkeleton() : FlowLines()} */}
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          width={"100%"}
          sx={{ marginTop: 5, padding: 5 }}
        >
          {!eu001 && showEquipments()}
        </Stack>
        {!eu001 && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            width={"85%"}
            sx={{ marginTop: 5 }}
          >
            <TextField
              id="end-use-comment"
              label={i18n.t("pages.license.add.end-use-comment")}
              // placeholder={i18n.t("pages.license.add.end-use-comment")}
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              error={!!formErrors["end_use_comment"]}
              helperText={formErrors["end_use_comment"]}
              required={true}
              disabled={disabledByRDO}
              value={currentLicense.end_use_comment || ""}
              onChange={(e) => {
                setCurrentLicense((prevLicense) => ({
                  ...prevLicense,
                  end_use_comment: e.target.value || "",
                }));
              }}
            />
            <TextField
              id="comment"
              label={i18n.t("pages.license.add.comment")}
              variant="outlined"
              multiline
              disabled={disabledByRDO}
              rows={4}
              fullWidth
              value={currentLicense.comment || ""}
              onChange={(e) => {
                setCurrentLicense((prevLicense) => ({
                  ...prevLicense,
                  comment: e.target.value,
                }));
              }}
            />
            <Box sx={{ marginTop: 5, marginBottom: 5 }} />
            {/* {loading ? renderSkeleton() : FlowLines()} */}
          </Stack>
        )}
      </Stack>

      <Box height="3em" />
      <Footer
        leftButtons={leftButtons}
        rightButtons={rightButtons}
        height="medium"
      />
      <ConfirmDialog
        open={openModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        confirmLabel={i18n.t("pages.license.add.licenseLineConfirm")}
        cancelLabel={i18n.t("pages.license.add.licenseLineCancel")}
        dialogText={i18n.t("pages.license.add.licenseModalTitle")}
        size="medium"
        confirmButtonColor="primary"
        cancelButtonColor="primary"
        confirmButtonVariant="contained"
        cancelButtonVariant="outlined"
      />
    </Container>
  );
};

export default inject("rootStore")(observer(EditLicense));
