/* Styles import */
import Api from "../../../utils/api";
import axios from "axios";
import {
  generateSelectOption,
  generateLicenseTypeOptions,
} from "../../../utils/generator";
import {
  regulation_rep,
  currency,
  countryCurrencyMap,
} from "../../../utils/constant";
import styles from "./log.module.css";

/* Global import */
import React, {
  Component,
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import {
  Title,
  Card,
  CardHeader,
  Loading,
  SearchInput,
  GridContainer,
  GridRow,
  GridCol,
  TextInput,
  Button,
  Checkbox,
  FormGroup,
  TextArea,
  RadioGroup,
  Radio,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { observer, inject } from "mobx-react";

/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderReportMenu from "../../../components/headerReportMenu";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { FormLabel } from "@material-ui/core";
import HeaderReport from "../../../components/headerReport";
import { useNavigate } from "react-router-dom";
interface Props {}
interface InjectedProps extends Props {
  history: import("history").History;
  rootStore: import("../../../stores/rootStore").default;
}
interface State {
  search: string;
  name?: string;
  erp: boolean;
  screening: string;
  departureCountryId: string;
  regulation?: LicenseRegulation;
  gridRef: any;
  setGridRef: any;
  entite: string;
}

type MsgType = {
  code_msg: string;
  Regle: string;
  Message: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};
interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
const Report: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const navigate = useNavigate();
  const [state, setState] = useState({
    disabledInputs: false,
    countries: [],
    regulation: "",
    search: "",
    msg: "",
    departureCountryId: "",
    MLmsg: "",
    erp: true,
    screening: "USE_BVD",
    gridRef: null,
    setGridRef: null,
    myRef: {
      current: {
        visibleColumns: [],
        data: [],
      },
    },
    entite: "ALL",
    licenseType: "" as LicenseType,
  });
  const [countries, setCountries] = useState([]);
  const [entities, setEntities] = useState([]);

  const msgCode = "EXP068";
  const bmsgCode = "EXP064";
  useEffect(() => {
    rootStore.countriesStore.getCountries();
    rootStore.modelStore.getModels();

    const fetchData3 = async () => {
      const tenant = rootStore.userStore.user?.tenantId!;
      try {
        const response1 = await fetch(
          `${
            process.env.REACT_APP_SCREEN
          }/get_entite?tenant=${encodeURIComponent(tenant || "")}`
        );
        const data1 = await response1.json();
        setEntities(data1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchData = async () => {
      await rootStore.tenantStore.getLog("all");
    };

    const fetchData2 = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SCREEN}/countries_departure`
        );
        //setState({ ...state, countries: response.data });
        setCountries(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des pays :", error);
      }
      try {
        const msgResponse = await api.GcodeToMsg(msgCode);
        setState({ ...state, msg: msgResponse as any });
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du message 'EXP068' :",
          error
        );
      }

      try {
        const MLmsgResponse = await api.GcodeToMsg(bmsgCode);
        setState({ ...state, MLmsg: MLmsgResponse as any });
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du message 'EXP064' :",
          error
        );
      }
    };
    fetchData();
    fetchData2();
    fetchData3();
  }, []);

  const isRegulationApplicableFR = () => {
    const { regulation } = state;
    return (
      regulation === "FR_LIST_DOUBLE_USAGE" ||
      regulation === "EU_DUAL_USE_LIST" ||
      regulation === "FR_MUNITIONS_LIST" ||
      regulation === "EU_MUNITIONS_LIST"
    );
  };
  const isRegulationApplicableFR_DUAL = () => {
    const { regulation } = state;
    return (
      regulation === "FR_LIST_DOUBLE_USAGE" || regulation === "EU_DUAL_USE_LIST"
    );
  };
  const isRegulationApplicableFR_Munition = () => {
    const { regulation } = state;
    return (
      regulation === "FR_MUNITIONS_LIST" || regulation === "EU_MUNITIONS_LIST"
    );
  };
  const selection_pay = (s: string) => {
    //alert(s);
    var options = document.querySelectorAll("#regulation option");
    const sb = document.getElementById("regulation") as HTMLSelectElement;
    setState({ ...state, departureCountryId: s });
    options.forEach((o) => o.remove());

    const option_tmp1 = new Option(i18n.t("pages.new-flow.select-value"), "");
    sb.options.add(option_tmp1, undefined);

    regulation_rep.map((option, index) => {
      if (
        option.value.substring(0, 2) == "EU" &&
        (s == "BE" || s == "NL" || s == "SE" || s == "SG" || s == "IT")
      ) {
        const option_tmp = new Option(option.label, option.value);
        if (sb) {
          sb.options.add(option_tmp, undefined);
        }
      }
    });

    regulation_rep.map((option, index) => {
      if (option.value.substring(0, 2) == s || s == "AL") {
        const option_tmp = new Option(option.label, option.value);
        if (sb) {
          sb.options.add(option_tmp, undefined);
        }
      }
    });
    regulation_rep.map((option, index) => {
      if (option.value.substring(0, 2) == "UK" && s == "GB") {
        //("okkkk dedans");
        const option_tmp = new Option(option.label, option.value);
        if (sb) {
          sb.options.add(option_tmp, undefined);
        }
      }
    });
  };

  //****************fin  CDC encours***************** */
  const dataSource = rootStore.tenantStore.datalog;
  const columns = [
    { name: "Date_heure", header: "Date_heure", minWidth: 150, defaultFlex: 2 },
    { name: "Client", header: "Clients", minWidth: 100, defaultFlex: 2 },
    { name: "User", header: "User", minWidth: 100, defaultFlex: 2 },
    { name: "Action", header: "Action", minWidth: 100, defaultFlex: 2 },
    { name: "value", header: "Value", minWidth: 100, defaultFlex: 2 },
    { name: "Result", header: "Result", minWidth: 100, defaultFlex: 2 },
    {
      name: "Departure_country",
      header: "Departure",
      minWidth: 100,
      defaultFlex: 2,
    },
    {
      name: "Destination_country",
      header: "Destination",
      minWidth: 100,
      defaultFlex: 2,
    },
    {
      name: "National_code",
      header: "National_code",
      minWidth: 100,
      defaultFlex: 2,
      defaultVisible: false,
    },
    {
      name: "US_code",
      header: "US_code",
      minWidth: 100,
      defaultFlex: 2,
      defaultVisible: false,
    },

    { name: "Info1", header: "Info1", minWidth: 100, defaultFlex: 1 },
    {
      name: "Info2",
      header: "Info2",
      minWidth: 100,
      defaultFlex: 1,
      defaultVisible: false,
    },
  ];
  const filterValue = [
    { name: "Client", operator: "contains", type: "string", value: "" },
    { name: "User", operator: "contains", type: "string", value: "" },
    { name: "Action", operator: "contains", type: "string", value: "" },
  ];

  const gridStyle = { minHeight: "500PX", marginTop: "15PX" };
  const SEPARATOR = ";";
  var dateJour = new Date();
  var afDate = Moment(dateJour).format("YYYY-MM-DD");
  const downloadBlob = (
    blob: any,
    fileName = afDate + " export control report.csv"
  ) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.position = "absolute";
    link.style.visibility = "hidden";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const exportXLS = () => {
    const columns = state.myRef.current.visibleColumns;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const header = columns.map((c: any) => c.header).join(SEPARATOR);
    const rows = state.myRef.current.data.map((data: any) =>
      columns.map((c: any) => data[c.id]).join(SEPARATOR)
    );

    const contents = [header].concat(rows).join("\n");
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });

    const ws = XLSX.utils.json_to_sheet(dataSource);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, afDate + " export control report" + fileExtension);
  };

  const exportCSV = () => {
    const columns = state.myRef.current.visibleColumns;

    const header = columns.map((c: any) => c.header).join(SEPARATOR);
    const rows = state.myRef.current.data.map((data: any) =>
      columns.map((c: any) => data[c.id]).join(SEPARATOR)
    );

    const contents = [header].concat(rows).join("\n");
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });

    downloadBlob(blob);
  };
  //check avant redirection

  /*
    - FR + DGA + EntitéSSS : redirection reportingDGA avec param
    - 0 entité + FR + DGA : redirection reportingDGA sans param
    - 1 entité + FR + DGA : redirection reportingDGA avec param
    - SBDU : FR + FR_LIST_DOUBLE_USAGE + licenseType
  */
  if (
    state.departureCountryId == "FR" &&
    state.regulation == "FR_MUNITIONS_LIST"
  ) {
    if (state.entite === "ALL" && entities.length === 0) {
      navigate("/reportingdga");
    }
    if (entities.length === 1 && state.entite !== "ALL") {
      navigate("/reportingdga", {
        state: { entite: state.entite, pays: state.departureCountryId },
      });
    }
    if (
      entities.length > 0 &&
      (state.entite !== "ALL" || rootStore.userStore.user?.entite !== "ALL")
    ) {
      if (state.entite !== "ALL") {
        navigate("/reportingdga", {
          state: { entite: state.entite, pays: state.departureCountryId },
        });
      } else {
        navigate("/reportingdga", {
          state: {
            entite: rootStore.userStore.user?.entite,
            pays: state.departureCountryId,
          },
        });
      }
    }
  }

  if (
    state.departureCountryId == "FR" &&
    state.regulation == "FR_LIST_DOUBLE_USAGE" &&
    state.licenseType
  ) {
    navigate("/reportingsbdu");
  }
  if (state.regulation && state.departureCountryId && state.licenseType) {
    if (
      state.departureCountryId == "US" &&
      state.regulation == "US_DUAL_USE_LIST_EAR"
    )
      navigate("/reportingusear");
    else if (
      state.departureCountryId == "US" &&
      state.regulation == "US_MUNITIONS_LIST_ITAR"
    )
      navigate("/reportingusitar");
    /*(
      state.regulation != "FR_MUNITIONS_LIST" &&
      state.departureCountryId !== "" &&
      state.regulation !== undefined
    )else {
    }*/
    if (
      state.departureCountryId !== "" &&
      state.departureCountryId !== "FR" &&
      state.regulation &&
      state.regulation !== "FR_MUNITIONS_LIST"
    ) {
      navigate("/reportingpays", {
        state: { pays: state.departureCountryId, reg: state.regulation },
      });
    }
  }

  return (
    <div>
      <AppBar selectedPage="ADMIN" rootStore={rootStore} />
      <HeaderReportMenu pages="REPORTING" rootStore={rootStore} />

      <div className={styles.body}>
        <Title tag="h2">{i18n.t("pages.search-flow.titre1")} </Title>
        <Title tag="h4">{i18n.t("pages.search-flow.titrereport1")} </Title>

        <GridContainer>
          <GridRow>
            <GridCol md={4} xs={12} alignSelf="center">
              <FormGroup>
                <FormLabel className={styles.label} required={true}>
                  {i18n.t("pages.exportability.departure-country")}
                </FormLabel>
                <select
                  id="departure-country"
                  required={false}
                  value={state.departureCountryId}
                  disabled={state.disabledInputs}
                  onChange={(e) => {
                    selection_pay(e.target.value);
                  }}
                >
                  <option value="AL">
                    {i18n.t("pages.new-flow.select-value")}
                  </option>
                  {generateSelectOption(countries)}
                </select>
              </FormGroup>
            </GridCol>
            <GridCol md={4} xs={12} alignSelf="center">
              <FormGroup>
                <FormLabel className={styles.label} required={true}>
                  {i18n.t("pages.license.add.regulation")}
                </FormLabel>
                <select
                  id="regulation"
                  value={state.regulation || ""}
                  required={true}
                  disabled={state.disabledInputs}
                  onChange={(e) => {
                    setState({
                      ...state,
                      regulation: e.target.value as LicenseRegulation,
                    });
                  }}
                >
                  <option value="">
                    {i18n.t("pages.new-flow.select-value")}
                  </option>

                  {generateSelectOption(regulation_rep)}
                </select>
              </FormGroup>
            </GridCol>
            {state.regulation == "FR_MUNITIONS_LIST" &&
              entities.length > 0 &&
              rootStore.userStore.user?.entite === "ALL" && (
                <GridCol md={3} xs={12} alignSelf="center">
                  <FormGroup>
                    <FormLabel className={styles.label} required={true}>
                      {"Entite"}
                    </FormLabel>
                    <select
                      onChange={(e) =>
                        setState({ ...state, entite: e.target.value })
                      }
                      value={state.entite || ""}
                    >
                      <option value={""}></option>

                      {entities.map((entite: any, index: number) => {
                        return (
                          <option key={index} value={entite.id_entite || ""}>
                            {entite.id_entite}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                </GridCol>
              )}
            {state.regulation !== "FR_MUNITIONS_LIST" && (
              <GridCol md={4} xs={12} alignSelf="center">
                <FormGroup>
                  <FormLabel className={styles.label} required={true}>
                    {i18n.t("pages.license.add.license-type")}
                  </FormLabel>
                  <select
                    id="license-type"
                    value={state.licenseType || ""}
                    required={true}
                    disabled={state.disabledInputs}
                    onChange={(e) => {
                      setState({
                        ...state,
                        licenseType: e.target.value as LicenseType,
                      });
                    }}
                  >
                    <option value="">
                      {i18n.t("pages.new-flow.select-value")}
                    </option>
                    {state.regulation === "US_DUAL_USE_LIST_EAR" &&
                      generateLicenseTypeOptions(["INDIVIDUAL"])}

                    {state.regulation === "US_MUNITIONS_LIST_ITAR" &&
                      generateLicenseTypeOptions([
                        "DSP5_PERMANENT",
                        "DSP73_TEMPORARY",
                        "WDA_WAREHOUSE_AGREEMENT",
                      ])}

                    {(state.regulation === "EU_DUAL_USE_LIST" ||
                      state.regulation === "FR_ARRETE_DU_31_JUILLET_2014_HEL" ||
                      state.regulation === "FR_LIST_DOUBLE_USAGE") &&
                      generateLicenseTypeOptions([
                        "INDIVIDUAL",
                        "GLOBAL",
                        "EU001_AUTOR_GENERAL",
                        "PERMANENT",
                      ])}

                    {state.regulation === "FR_IMPORTATION" &&
                      generateLicenseTypeOptions([
                        "AIPE",
                        "AIMG",
                        "AITMG",
                        "AGIMG",
                      ])}

                    {(state.regulation === "DE_DUAL_USE_GUTER" ||
                      state.regulation === "DE_MUNITIONS_LIST") &&
                      generateLicenseTypeOptions([
                        "INDIVIDUAL",
                        "GLOBAL",
                        "COLLECTIVE",
                      ])}

                    {(state.regulation === "UK_DUAL_USE_LIST" ||
                      state.regulation === "UK_MILITARY_LIST") &&
                      generateLicenseTypeOptions([
                        "GLOBAL_OGEL",
                        "INDIVIDUAL",
                        "TEMPORARY",
                      ])}

                    {(state.regulation === "ES_DUAL_USE_LIST" ||
                      state.regulation === "ES_MUNITION_LIST" ||
                      state.regulation === "EU_MUNITIONS_LIST") &&
                      state.departureCountryId != "NL" &&
                      generateLicenseTypeOptions([
                        "GLOBAL",
                        "IMPORT",
                        "INDIVIDUAL",
                        "TEMPORARY",
                      ])}

                    {state.departureCountryId === "NL" &&
                      state.regulation === "EU_MUNITIONS_LIST" &&
                      generateLicenseTypeOptions([
                        "INDIVIDUAL",
                        "GLOBAL",
                        "NL005_AUTOR_GENERAL",
                        "NL006_AUTOR_GENERAL",
                      ])}
                  </select>
                </FormGroup>
              </GridCol>
            )}
          </GridRow>
          <GridRow></GridRow>
        </GridContainer>
      </div>
    </div>
  );
};

export default inject("rootStore")(observer(Report));
