/* Styles import */
import styles from "./listCountry.module.css";

/* Global import */
import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Create";
import { IconButton } from "../../components/horizon-components-react/src/components";

interface Props {
  list: any;
  country: string;
  categorie: string;
}

const ListCountryEdit = ({ list, country, categorie }: Props) => {
  function modifier(categorie: string, index: number, ligne: string) {
    const url = prompt("Nouvelle URl", ligne);
    if (url) {
      fetch(
        `${process.env.REACT_APP_SCREEN}/controle_send?categorie=${categorie}&index=${index}&ligne=${url}`
      )
        .then((response) => {
          return response.text();
        })
        .then((resultat) => {
          alert("Mise a jour bien effectuée");
          window.location.reload();
        });
    }
  }

  //const array_value=liens.replace("[","").replace("]","").split(',')

  return (
    <li className={styles.element} key={country}>
      {" "}
      {country} :
      <ul className={styles.list}>
        {list.map((list: any) => {
          return (
            <li key={list.id}>
              {list.titre}

              <input
                name={list.id}
                type="text"
                value={list.url}
                onClick={() => modifier(categorie, list.id, list.url)}
                style={{ width: "100%" }}
              />
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default ListCountryEdit;
