/* Styles import */
import styles from "../admin.module.css";
import SearchIcon from "@material-ui/icons/Add";
/* Global import */
import React, { Component, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/DeleteSweep";
import {
  Title,
  Checkbox,
  IconButton,
  TextInput,
  Button,
  GridContainer,
  GridRow,
  GridCol,
  Card,
  CardHeader,
  CardContent,
  FormGroup,
} from "../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { observer, inject } from "mobx-react";
import { Hidden, Tooltip } from "@material-ui/core";
/* Custom import */
import AppBar from "../../../components/appBar";
import HeaderAdmin from "../../../components/headerAdmin";
import { getToLocaleDate } from "../../../utils/date";
import ProgressUpload from "../../../components/progressUpload";

interface Props {
  rootStore: import("../../../stores/rootStore").default;
}
interface State {
  tenant: string;
  standard: string;
  templete_ligne: string;
  ligne_data: string;
  part_number: string;
  eu_classification: string;
  eu_hs_code: string;
  us_classification: string;
  us_hts_code: string;
}
interface UpdatedProps {
  [key: string]: any; // Définissez les types appropriés pour les propriétés mises à jour
}

const Entite_templete: React.FC<Props> = ({ rootStore }) => {
  const [tenant, setTenant] = useState<string>("");
  const [standard, setStandard] = useState<string>("OUI");
  const [templete_ligne, setTemplete_ligne] = useState<string>("1");
  const [ligne_data, setLigne_data] = useState<string>("2");
  const [part_number, setPart_number] = useState<string>("");
  const [eu_classification, setEu_classification] = useState<string>("");
  const [eu_hs_code, setEu_hs_code] = useState<string>("");
  const [us_classification, setUs_classification] = useState<string>("");
  const [us_hts_code, setUs_hts_code] = useState<string>("");

  const saveR_checklist = () => {
    // Envoyer les données à l'API Laravel
    fetch(`${process.env.REACT_APP_SCREEN}/updateTenantTemplete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tenant,
        standard,
        templete_ligne,
        ligne_data,
        part_number,
        eu_classification,
        eu_hs_code,
        us_classification,
        us_hts_code,
      }), // Envoyer la liste Q_checklist au format JSON
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        // Gérer les erreurs lors de l'appel à l'API
        console.error(error);
      });
  };

  // eslint-disable-next-line

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(window.location.search);
      const paramValue = params.get("parametre");
      const tenantValue = paramValue || "";

      setTenant(tenantValue);

      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_SCREEN
          }/getEntiteTemplete?tenant=${encodeURIComponent(tenantValue)}`
        );

        const data = await response.json();
        //alert(data.standard);
   if (data.standard) 
   {    
        setStandard(data.standard);
        setTemplete_ligne(data.templete_ligne);
        setLigne_data(data.ligne_data);
        setPart_number(data.part_number);
        setEu_classification(data.eu_classification);
        setEu_hs_code(data.eu_hs_code);
        setUs_classification(data.us_classification);
        setUs_hts_code(data.us_hts_code);
    

  }      
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [""]);

  const renderRStep = () => {
    return (
      <div>
        <div className={styles.equipmentBox}>
          <GridRow>
            <GridCol md={5}>
              <div
                className={styles.equipmentHeader}
                style={{ marginLeft: "20px" }}
              ></div>
            </GridCol>

            <GridCol md={"auto"}></GridCol>

            <GridCol md={2}>
              <div className={styles.equipmentHeader}></div>
            </GridCol>
          </GridRow>
        </div>

        <GridContainer>{renderRStep1(" ")}</GridContainer>
      </div>
    );
  };

  const renderRStep1 = (key: string) => {
    return (
      <div>
        <div className={styles.equipmentBox}>
          <GridRow>
            <GridCol md={2}>{i18n.t("pages.templete.type")}</GridCol>

            <GridCol md={5}>
              <div>
                <label>
                  <input
                    type="radio"
                    name="standard"
                    value="OUI"
                    style={{ marginRight: "5px" }}
                    checked={standard === "OUI"}
                    onChange={(e) => setStandard(e.target.value)}
                  />
                  {i18n.t("pages.templete.type_choix1")} 
                </label>
                <label style={{ marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="standard"
                    value="NON"
                    style={{ marginRight: "5px" }}
                    checked={standard === "NON"}
                    onChange={(e) => setStandard(e.target.value)}
                  />
                  {i18n.t("pages.templete.type_choix2")}
                </label>
              </div>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol md={2}>{i18n.t("pages.templete.ligne_code")}</GridCol>

            <GridCol md={5}>
              <div>
                <label>
                  <input
                    type="radio"
                    name="rules"
                    value="1"
                    style={{ marginRight: "5px" }}
                    checked={(templete_ligne == "1")?true:false}
                    onChange={(e) => setTemplete_ligne(e.target.value)}
                  />
                  {i18n.t("pages.templete.code1")} 
                </label>
                <label style={{ marginRight: "10px" }}>
                  <input
                    type="radio"
                    name="rules"
                    value="2"
                    style={{ marginRight: "5px" }}
                    checked={(templete_ligne == "2")?true:false}
                    onChange={(e) => setTemplete_ligne(e.target.value)}
                  />
                  {i18n.t("pages.templete.code2")}
                </label>
              </div>
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol md={2}>{i18n.t("pages.templete.premiere_ligne")}</GridCol>

            <GridCol md={5}>
              <TextInput
                id="rule"
                label=""
                value={ligne_data || ""}
                type="text"
                min={0}
                step={1}
                style={{ width: "40px" }}
                errorText={i18n.t("errors")}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    // Vérifie si la valeur est un nombre entier
                    setLigne_data(value);
                  }
                }}
              />
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol md={2}>Part number</GridCol>

            <GridCol md={5}>
              <TextInput
                id="rule"
                label=""
                value={part_number || ""}
                type="text"
                min={0}
                step={1}
                style={{ width: "40px" }}
                maxLength={1}
                errorText={i18n.t("errors")}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  if (value.match(/^[A-Z]?$/)) {
                    setPart_number(value);
                  }
                }}
              />
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol md={2}>EU classification</GridCol>

            <GridCol md={5}>
              <TextInput
                id="rule"
                label=""
                value={eu_classification || ""}
                type="text"
                min={0}
                step={1}
                style={{ width: "40px" }}
                maxLength={1}
                errorText={i18n.t("errors")}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  if (value.match(/^[A-Z]?$/)) {
                    setEu_classification(value);
                  }
                }}
              />
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol md={2}>EU HS code</GridCol>

            <GridCol md={5}>
              <TextInput
                id="rule"
                label=""
                value={eu_hs_code || ""}
                type="text"
                min={0}
                step={1}
                style={{ width: "40px" }}
                maxLength={1}
                errorText={i18n.t("errors")}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  if (value.match(/^[A-Z]?$/)) {
                    setEu_hs_code(value);
                  }
                }}
              />
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol md={2}>US classification</GridCol>

            <GridCol md={5}>
              <TextInput
                id="rule"
                label=""
                value={us_classification || ""}
                type="text"
                min={0}
                step={1}
                style={{ width: "40px" }}
                maxLength={1}
                errorText={i18n.t("errors")}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  if (value.match(/^[A-Z]?$/)) {
                    setUs_classification(value);
                  }
                }}
              />
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol md={2}>US HTS code</GridCol>

            <GridCol md={5}>
              <TextInput
                id="rule"
                label=""
                value={us_hts_code || ""}
                type="text"
                min={0}
                step={1}
                style={{ width: "40px" }}
                maxLength={1}
                errorText={i18n.t("errors")}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  if (value.match(/^[A-Z]?$/)) {
                    setUs_hts_code(value);
                  }
                }}
              />
            </GridCol>
          </GridRow>
        </div>

        <span style={{ marginLeft: "35px" }}></span>

        <Button
          id="add-equipment"
          type="button"
          onClick={() => saveR_checklist()}
          size="medium"
        >
          Save
        </Button>
      </div>
    );
  };
  return (
    <div>
      <AppBar selectedPage="ADMIN" rootStore={rootStore} />

      <HeaderAdmin pages="CLASSIFICATION_DATABASE" rootStore={rootStore} />

      <Card className={styles.body}>
        <CardHeader title={i18n.t("pages.templete.title") + " " + tenant} />

        <CardContent>
          <FormGroup>{renderRStep()}</FormGroup>
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(Entite_templete));
