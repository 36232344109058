/* Styles import */
import styles from "./result.module.css";
import moment from "moment";
import "moment/locale/fr";
import jsPDF from "jspdf";
/* Global import */
import React, { Component, useEffect, useState } from "react";
import {
  GridCol,
  GridRow,
  Title,
} from "../../../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
// eslint-disable-next-line
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Api from "../../../../utils/api";
/* Custom import */
import html2canvas from "html2canvas";
import AppBar from "../../../../components/appBar";
import Footer from "../../../../components/footer";
import { flowUrl } from "../../../../utils/generator";
import TimeLine from "../../../../components/timeLine";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "../../../../components/horizon-components-react/src/components";
interface State {
  flowId?: string;
  post?: BasicEntityFinale[];
  nosanction: string;
}
type MsgType = {
  code_msg: string;
  Regle: string;
  Message: string;
  Lib_url1: string;
  Url1: string;
  Lib_url2: string;
  Url2: string;
  Lib_url3: string;
  Url3: string;
};

interface InjectedProps {
  rootStore: import("../../../../stores/rootStore").default;
}
interface Props extends InjectedProps {}

const ResultBasicEntity: React.FC<Props> = ({ rootStore }) => {
  const api = new Api();
  const { id, unique, company_us } = useParams();

  const location = useLocation();
  let company = "";
  //console.log(location.state.company)
  if (location.state) {
    company = location.state.company;
  }

  const flowId = id;
  const navigate = useNavigate();
  const navigateTowards = (url: string) => {
    navigate(flowUrl(url, id));
  };
  const goBack = () => {
    navigate(-1);
  };

  const [msg, setMsg] = useState({
    code_msg: "",
    Regle: "",
    Message: "",
    Lib_url1: "",
    Url1: "",
    Lib_url2: "",
    Url2: "",
    Lib_url3: "",
    Url3: "",
  });

  const [msg1, setMsg1] = useState("");
  const [Lib1, setLib1] = useState("");
  const [Url1, setUrl1] = useState("");
  const [Lib2, setLib2] = useState("");
  const [Url2, setUrl2] = useState("");
  const [Lib3, setLib3] = useState("");
  const [Url3, setUrl3] = useState("");

  const [DPmsg1, setDPMsg1] = useState("");
  const [DPLib1, setDPLib1] = useState("");
  const [DPUrl1, setDPUrl1] = useState("");
  const [DPLib2, setDPLib2] = useState("");
  const [DPUrl2, setDPUrl2] = useState("");
  const [DPLib3, setDPLib3] = useState("");
  const [DPUrl3, setDPUrl3] = useState("");

  const [bmsg1, setBMsg1] = useState("");
  const [bLib1, setBLib1] = useState("");
  const [bUrl1, setBUrl1] = useState("");
  const [bLib2, setBLib2] = useState("");
  const [bUrl2, setBUrl2] = useState("");
  const [bLib3, setBLib3] = useState("");
  const [bUrl3, setBUrl3] = useState("");

  const [smsg1, setSMsg1] = useState("");
  const [sLib1, setSLib1] = useState("");
  const [sUrl1, setSUrl1] = useState("");
  const [sLib2, setSLib2] = useState("");
  const [sUrl2, setSUrl2] = useState("");
  const [sLib3, setSLib3] = useState("");
  const [sUrl3, setSUrl3] = useState("");
  const [post, setPost] = useState<BasicEntityFinale_eu[]>();
  const [nosanction, setNosanction] = useState("");

  const [state, setState] = useState({
    nosanction: "Screening .... ",
  });
  const getButtons = () => {
    const buttons = [
      {
        id: "previous",
        onClick: () =>
          navigateTowards("/screening/basic/us/choose-basic-entity"),
        label: i18n.t("global.previous"),
        primary: false,
        disabled: false,
      },
      {
        id: "PDF",
        onClick: () => handleDownloadImage(),
        label: i18n.t("global.pdf"),
      },
      {
        id: "due",
        onClick: () => {
          let currentPostList = null as any;

          // Déterminer quelle liste utiliser
          if (rootStore.screeningStore.liste_fr) {
            currentPostList = post;
          } else if (rootStore.screeningStore.liste_eu) {
            currentPostList = post;
          } else if (rootStore.screeningStore.liste_uk) {
            currentPostList = post;
          } else if (rootStore.screeningStore.liste_canadien) {
            currentPostList = post;
          } else if (rootStore.screeningStore.liste_us) {
            currentPostList = company_us;
          }
          const idEntity =
            currentPostList && currentPostList.length > 0
              ? currentPostList[0].name
              : null;

          navigate(flowUrl(`/screening/basic/due_diligence/${idEntity}`));
        },
        label: i18n.t("global.due_diligence"),
      },
    ];

    if (flowId) {
      buttons.push({
        id: "licensing",
        onClick: () => navigateTowards("/license/matching"),
        label: i18n.t("global.licensing"),
        primary: true,
        disabled: !(
          rootStore.screeningStore.screeningBasicEntityResult &&
          rootStore.screeningStore.screeningBasicEntityResult
            .trafficLightColor === "GREEN"
        ),
      });
    }
    return buttons;
  };

  const bmsg = "EXP036";
  const tmpmsg = "EXP035";
  const smsg = "EXP055";

  useEffect(() => {
    const fetchData = async () => {
      try {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        let response = await api.GcodeToMsg(tmpmsg);
        setMsg1(response.Message);
        setLib1(response.Lib_url1);
        setUrl1(response.Url1);
        setLib2(response.Lib_url2);
        setUrl2(response.Url2);
        setLib3(response.Lib_url3);
        setUrl3(response.Url3);

        response = await api.GcodeToMsg(bmsg);
        setBMsg1(response.Message);
        setBLib1(response.Lib_url1);
        setBUrl1(response.Url1);
        setBLib2(response.Lib_url2);
        setBUrl2(response.Url2);
        setBLib3(response.Lib_url3);
        setBUrl3(response.Url3);

        response = await api.GcodeToMsg(smsg);
        setSMsg1(response.Message);
        setSLib1(response.Lib_url1);
        setSUrl1(response.Url1);
        setSLib2(response.Lib_url2);
        setSUrl2(response.Url2);
        setSLib3(response.Lib_url3);
        setSUrl3(response.Url3);
        const DPmsgData = await api.GcodeToMsg("EXP109");

        setDPMsg1(DPmsgData.Message);
        setDPLib1(DPmsgData.Lib_url1);
        setDPUrl1(DPmsgData.Url1);
        setDPLib2(DPmsgData.Lib_url2);
        setDPUrl2(DPmsgData.Url2);
        setDPLib3(DPmsgData.Lib_url3);
        setDPUrl3(DPmsgData.Url3);
        setDPLib3(DPmsgData.Lib_url3);

        const screenResponse = await fetch(
          `${process.env.REACT_APP_SCREEN}/screenEntite?companyName=` +
            encodeURIComponent(`${company}`)
        );
        const screenData = await screenResponse.json();

        setPost(screenData.resultat);
        setNosanction(screenData.resultat ? "" : i18n.t("global.nosanction"));
      } catch (error) {
        console.error("Error fetching data:", error);
        setState({
          nosanction: i18n.t("global.nosanction"),
        });
      }
    };

    fetchData(); // Appeler la fonction fetchData lorsque le composant est monté
  }, [flowId, company, i18n]);

  const handleDownloadImage = async () => {
    const element = document.getElementById("print")!;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");
    const maDate = new Date();
    const location = useLocation();
    const { state } = location;
    const company = state.company;
    const auteur =
      rootStore.userStore.user?.firstname +
      " " +
      rootStore.userStore.user?.lastname;

    const societe = rootStore.userStore.user?.tenantId;
    var afDate = moment(maDate).format("YYYY-MM-DD");

    var doc = new jsPDF("p", "mm");
    let country = window.navigator.language;
    let datrap = moment().locale("en").format("ll");

    if (country === "fr-FR") datrap = moment().locale("fr").format("ll");

    doc.setFontSize(14);
    let width = doc.internal.pageSize.getWidth();
    doc.text(
      i18n.t("global.titrescreening"),
      doc.internal.pageSize.getWidth() / 2 - 20,
      10
    );

    doc.setFontSize(10);
    doc.text(i18n.t("global.rapport") + " " + datrap, 10, 20);
    doc.text(i18n.t("global.autheur") + " : " + auteur, 10, 25);
    doc.text(i18n.t("global.societe") + " : " + societe, 10, 30);
    let dcountry = "";
    let acountry = "";
    /*
    if (result) {
      dcountry = result.departureCountry.name
      acountry = result.countryOfDestination.name
    } 
    */
    let height = doc.internal.pageSize.getHeight();

    let widthRatio = width / canvas.width;
    let heightRatio = height / canvas.height;

    let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
    doc.addImage(
      data,
      "JPEG",
      0,
      40,
      canvas.width * ratio,
      canvas.height * ratio
    );

    doc.text(
      i18n.t("global.footer"),
      doc.internal.pageSize.getWidth() / 2 - 30,
      270
    );
    doc.save(afDate + " US Screening " + company + ".pdf");

    const link = document.createElement("a");
    // link.href = data;
    // link.download = 'downloaded-image.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const screeningBasicEntityResult =
    rootStore.screeningStore.screeningBasicEntityResult;

  const result = rootStore.exportabilityStore.screeningCountryResult;

  return (
    <div>
      <AppBar rootStore={rootStore} selectedPage={"SCREENING"} />

      <div className={styles.image}></div>

      <div className={styles.body}>
        <Title tag="h2">
          {i18n.t("pages.screening.entity.result.titleBasic")}
        </Title>
        <div id="print">
          {screeningBasicEntityResult && (
            <div className={styles.message}>
              <div
                className={`${styles.traficLightBox} ${
                  styles[
                    screeningBasicEntityResult.trafficLightColor.toLowerCase()
                  ]
                }`}
              ></div>
              <img
                className={styles.traficLight}
                src={require(
                  `../../../../../statics/images/traffic-${screeningBasicEntityResult.trafficLightColor.toLowerCase()}.svg`
                )}
                alt={"trafic"}
              />

              <div>
                <p>
                  {post &&
                    post.map((s) => (
                      <div>
                        <b>
                          {" "}
                          {s.type === "Individual"
                            ? screeningBasicEntityResult.message.replace(
                                "entity",
                                "person"
                              )
                            : screeningBasicEntityResult.message}{" "}
                        </b>
                      </div>
                    ))}
                  {!post && (
                    <div>
                      <b>
                        {nosanction}

                        {rootStore.screeningStore.company}
                      </b>
                    </div>
                  )}
                  <div>
                    <br />
                  </div>
                  {post &&
                    post.map(
                      (s) =>
                        s.source ===
                          "Entity List (EL) - Bureau of Industry and Security" && (
                          <div>
                            <div>
                              <p>
                                {msg1 &&
                                  msg1.split("<br>").map((i, key) => {
                                    return <div key={key}>{i}</div>;
                                  })}
                              </p>
                            </div>
                            <div>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={Url1}
                              >
                                {Lib1}
                              </a>
                              <a
                                target="_blank"
                                style={{ marginLeft: "50px" }}
                                rel="noopener noreferrer"
                                href={Url2}
                              >
                                {Lib2}
                              </a>
                              <a
                                target="_blank"
                                style={{ marginLeft: "50px" }}
                                rel="noopener noreferrer"
                                href={Url3}
                              >
                                {Lib3}
                              </a>
                            </div>
                          </div>
                        )
                    )}
                  {post &&
                    post.map(
                      (s) =>
                        s.source.includes("ITAR Debarred (DTC)") && (
                          <div>
                            <div>
                              <p>
                                {DPmsg1 &&
                                  DPmsg1.split("<br>").map((i, key) => {
                                    return <div key={key}>{i}</div>;
                                  })}
                              </p>
                            </div>
                            <div>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={DPUrl1}
                              >
                                {DPLib1}
                              </a>
                              <a
                                target="_blank"
                                style={{ marginLeft: "50px" }}
                                rel="noopener noreferrer"
                                href={DPUrl2}
                              >
                                {DPLib2}
                              </a>
                              <a
                                target="_blank"
                                style={{ marginLeft: "50px" }}
                                rel="noopener noreferrer"
                                href={DPUrl3}
                              >
                                {DPLib3}
                              </a>
                            </div>
                          </div>
                        )
                    )}

                  {post &&
                    post.map(
                      (s) =>
                        s.source.includes(
                          "Military End User (MEU) List - Bureau of Industry and Security"
                        ) && (
                          <div>
                            <div>
                              <p>
                                {bmsg1 &&
                                  bmsg1.split("<br>").map((i, key) => {
                                    return <div key={key}>{i}</div>;
                                  })}
                              </p>
                            </div>
                            <div>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={bUrl1}
                              >
                                {bLib1}
                              </a>

                              <a
                                target="_blank"
                                style={{ marginLeft: "50px" }}
                                rel="noopener noreferrer"
                                href={bUrl2}
                              >
                                {bLib2}
                              </a>
                              <a
                                target="_blank"
                                style={{ marginLeft: "50px" }}
                                rel="noopener noreferrer"
                                href={bUrl3}
                              >
                                {bLib3}
                              </a>
                            </div>
                          </div>
                        )
                    )}

                  {post &&
                    post.map(
                      (s) =>
                        s.source.includes(
                          "Specially Designated Nationals (SDN)"
                        ) && (
                          <div>
                            <div>
                              <p>
                                {smsg1 &&
                                  smsg1.split("<br>").map((i, key) => {
                                    return <div key={key}>{i}</div>;
                                  })}
                              </p>
                            </div>
                            <div>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={sUrl1}
                              >
                                {sLib1}
                              </a>

                              <a
                                target="_blank"
                                style={{ marginLeft: "50px" }}
                                rel="noopener noreferrer"
                                href={sUrl2}
                              >
                                {sLib2}
                              </a>
                              <a
                                target="_blank"
                                style={{ marginLeft: "50px" }}
                                rel="noopener noreferrer"
                                href={sUrl3}
                              >
                                {sLib3}
                              </a>
                            </div>
                          </div>
                        )
                    )}
                </p>
              </div>
            </div>
          )}
          <br />
          <br />
          {post &&
            post.map((s) => (
              <Card>
                <Table>
                  <TableRow>
                    <TableCell>{"Source"}</TableCell>
                    <TableCell>{s.source}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{"Type"}</TableCell>
                    <TableCell>{s.type}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{"Start date"}</TableCell>
                    <TableCell>{s.start_date}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{"Remarks"}</TableCell>
                    <TableCell>{s.remarks}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{"Source list"}</TableCell>
                    <TableCell>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={s.source_list_url}
                      >
                        {s.source_list_url}
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{"License requirement"}</TableCell>
                    <TableCell>{s.license_requirement}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{"License policy"}</TableCell>
                    <TableCell>{s.license_policy}</TableCell>
                  </TableRow>
                </Table>
              </Card>
            ))}
        </div>
      </div>

      <Footer buttons={getButtons()}>
        {flowId && (
          <GridRow>
            <GridCol md={12} xs={12} alignSelf="center">
              <TimeLine step={2} />
            </GridCol>
          </GridRow>
        )}
      </Footer>
    </div>
  );
};

export default inject("rootStore")(observer(ResultBasicEntity));
