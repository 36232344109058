/* Styles import */
import styles from "./searchEccn.module.css";
import Api from "../../utils/api";
import moment from "moment";
import "moment/locale/fr";
import jsPDF from "jspdf";
import itarml from "./itarMl.json";
import Modal from "@material-ui/core/Modal";
//CONSTANT
import { countries } from "../../utils/constant";
import Box, { BoxProps } from "@mui/material/Box";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
/* Global import */
import React, {
  Component,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { observer, inject } from "mobx-react";
import {
  FormGroup,
  TextArea,
  Checkbox,
  TextInput,
  Title,
  SearchInput,
  IconButton,
  Loading,
  Button,
} from "../../components/horizon-components-react/src/components";
import Visibility from "@material-ui/icons/Close";
import i18n from "i18next";
import html2canvas from "html2canvas";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useNavigate } from "react-router-dom";
/* Custom import */
import RightPanel from "../rightPanel";
import EccnComponent from "../eccn";
import EccnComponentOrigine from "../eccn origine";
import { CCountry, itemType } from "../../utils/constant";
import { generateSelectOption } from "../../utils/generator";
import { promises } from "fs";
import axios from "axios";
import { getCountry } from "../../utils/navigator";
import TextFieldMUI from "@mui/material/TextField";
import IconButtonMUI from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputMUI from "@mui/material/OutlinedInput";

import CrossIcon from "@mui/icons-material/Clear";
import Magnifier from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { wait } from "@testing-library/react";
import eccn from "../eccn";

interface Props {
  isOpen: boolean;
  onClose: (eccn?: ClassificationEccn) => void;
  rootStore: import("../../stores/rootStore").default;
  exportabilityMode?: boolean;
  ouClick: string;
}

interface State {
  selectedEccn?: Eccn;
  countries?: CCountry[];
  selectedEccnUS?: Eccn;
  prevSearchDeepSearch: string;
  checkbox: boolean;
  go: boolean;
  forcemono: boolean;
  noSoloEccn: Eccn[];
  Q_checklist: Q_checklist[];
  Reponses: string[];
  ReponsesSousQ: { type: string; reponse: string }[][];
  textlibre: string;
  all_liste: boolean;
  titre_modal: boolean;
  titre_modal_text: string;
  intangible: boolean;
  wizard: boolean;
  search_type: number;
  texte_libre: boolean;
  open: boolean;
  setOpen: boolean;
  open1: boolean;
  setOpen1: boolean;
}

const SearchEccn: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const [searchTypeFromStore, setSearchTypeFromStore] =
    useState<string>("KEYWORD");
  const [selectedEccn, setSelectedEccn] = useState<Eccn | undefined>();
  //const [countries, setCountries] = useState<any[]>();


  const [selectedEccnUS, setSelectedEccnUS] = useState<Eccn | undefined>();
  const [prevSearchDeepSearch, setPrevSearchDeepSearch] = useState<string>("");
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [go, setGo] = useState<boolean>(false);
  const [forcemono, setForcemono] = useState<boolean>(false);
  const [noSoloEccn, setNoSoloEccn] = useState<Eccn[]>([]);
  const [Q_checklist, setQChecklist] = useState<Q_checklist[]>([]);
  const [Reponses, setReponses] = useState<string[]>([]);
  const [ReponsesSousQ, setReponsesSousQ] = useState<
    { type: string; reponse: string }[][]
  >([]);
  const [textlibre, setTextlibre] = useState<string>("");
  const [all_liste, setAllListe] = useState<boolean>(false);
  const [titre_modal, setTitreModal] = useState<boolean>(false);
  const [titre_modal_text, setTitreModalText] = useState<string>("");
  const [intangible, setIntangible] = useState<boolean>(false);
  const [wizard, setWizard] = useState<boolean>(false);
  const [search_type, setSearch_type] = useState<number>(1);
  const [search_templete, setSearch_templete] = useState<number>(0);
  const [texte_libre, setTexteLibre] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);
  const [searchInputLocal, setSearchInput] = useState<string>("");
  const [countryCode, setCountryCode] = useState<EccnCountry>(getCountry());
  const [usOrigin, setUsOrigin] = useState<boolean>(
    props.rootStore.exportabilityStore.usOrigin
  );
  //const [ReponsesSousQ, setReponsesSousQ] = useState<any[][][]>([]);
  const resultRef = useRef<HTMLDivElement>(null);

  const api = new Api();
  const handleChangeSearchType = (event: any) => {
    setGo(false);
    setSearchTypeFromStore(event);
    if (event !== null) {
      props.rootStore.eclabsStore.setSearchType(event);
    }
    if (
      props.rootStore.eclabsStore.searchType === "PARTNUMBER" ||
      props.rootStore.eclabsStore.searchType === "CHECKLIST"
    ) {
      setUsOrigin(true);
      props.rootStore.exportabilityStore.usOrigin = true;
    }

    if (props.rootStore.eclabsStore.searchType === "KEYWORD") {
      if (props.rootStore.eclabsStore.countryCode === "ALL") {
        props.rootStore.eclabsStore.setCountryCode("FR");
      }
    }
    if (wizard) {
      props.rootStore.exportabilityStore.usOrigin = true;
    }

    if (props.rootStore.eclabsStore.searchType === "PARTNUMBER") {
      if (search_templete !== 1) {
        setCountryCode("ALL");
        props.rootStore.eclabsStore.setCountryCode("ALL");
      }
    } else {
      setCountryCode(getCountry());
    }
    setGo(false);
  };

  const handleChangeSousQ = (
    event: any,
    qIndex: number,
    sqIndex: number,
    type: string
  ) => {
    setReponsesSousQ(ReponsesSousQ.slice());
    const newReponsesSousQ: any[][] = [...ReponsesSousQ];
    newReponsesSousQ[qIndex][sqIndex] = {
      type: type,
      reponse: event.target.value,
    };
    setReponsesSousQ(newReponsesSousQ);
    props.rootStore.eclabsStore.setReponseSousQ(newReponsesSousQ);
  };

  const handleChangequestion = (e: any, index: number) => {
    let updatedReponses = [...Reponses]; // Créer une copie du tableau Reponses
    updatedReponses[index] = e.target.value; // Mettre à jour la valeur à l'index spécifié
    setReponses(updatedReponses);
    props.rootStore.eclabsStore.setReponse(updatedReponses);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tenant = props.rootStore.userStore.user?.tenantId;

        // Appel API pour les questions
        /*
        const questionsResponse = await fetch(
          `${
            process.env.REACT_APP_SCREEN
          }/get_Question?tenant=${encodeURIComponent(tenant || "")}`
        );
        const questionsData = await questionsResponse.json();
*/

        if (props.rootStore.tenantStore.questionsResponse === undefined) {
          //alert(props.rootStore.userStore.user?.tenantId);
          await props.rootStore.tenantStore.getTenanttempleteData();
          await props.rootStore.tenantStore.getTenantAhData();
          await props.rootStore.tenantStore.getQuestionsResponse();
        }
        const questionsData = props.rootStore.tenantStore.questionsResponse;
        // Initialisation des Réponses
        //  console.log(questionsData);
        const initialReponses = questionsData.map(() => "NON");

        const initialReponsesSousQ = questionsData.map(
          (q: { sous_q: { yes_no: any }[] }) =>
            q.sous_q.map((sq: { yes_no: any }) => ({
              type: sq.yes_no || "",
              reponse: "NON",
            }))
        );

        // Appel API pour obtenir le statut du wizard
        /*
        const tenantAhResponse = await fetch(
          `${
            process.env.REACT_APP_SCREEN
          }/getTenantAh?name=${encodeURIComponent(`${tenant}`)}`
        );
        const tenantAhData = await tenantAhResponse.json();
*/

        if (!props.rootStore.tenantStore.tenantAhData)
          props.rootStore.tenantStore.getTenantAhData();
        const tenantAhData = props.rootStore.tenantStore.tenantAhData;

        /*        
        const tenanttempleteResponse = await fetch(
          `${
            process.env.REACT_APP_SCREEN
          }/getTenanttemplete?name=${encodeURIComponent(`${tenant}`)}`
        );
        const tenanttempleteData = await tenanttempleteResponse.json();
*/
        if (props.rootStore.tenantStore.tenanttempleteData)
          props.rootStore.tenantStore.getTenanttempleteData();
        const tenanttempleteData =
          props.rootStore.tenantStore.tenanttempleteData;

        //setCountries(countriesResponse.data);
        setQChecklist(questionsData);
        setReponses(initialReponses);
        props.rootStore.eclabsStore.setReponse(initialReponses);
        setReponsesSousQ(initialReponsesSousQ);
        if (tenantAhData?.wizard!) setWizard(tenantAhData.wizard! === 1);

        if (tenantAhData?.search_type!)
          setSearch_type(tenantAhData?.search_type!);
        setSearch_templete(tenanttempleteData);
        if (tenantAhData?.search_type! == 1) {
          setSearchTypeFromStore("KEYWORD");
          props.rootStore.eclabsStore.setSearchType("KEYWORD");
        }
        if (tenantAhData?.search_type! == 2) {
          setSearchTypeFromStore("PARTNUMBER");
          props.rootStore.eclabsStore.setSearchType("PARTNUMBER");
        }
        if (tenantAhData?.search_type! == 3) {
          setSearchTypeFromStore("CHECKLIST");
          props.rootStore.eclabsStore.setSearchType("CHECKLIST");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [props.rootStore.tenantStore.questionsResponse]);

  useLayoutEffect(() => {
    if (prevSearchDeepSearch && selectedEccn) {
      const elem = document.getElementById(selectedEccn.code);

      if (elem) {
        const topPos = elem.offsetTop;
        const elemParent = document.getElementById("result");

        if (elemParent) {
          elemParent.scrollTop = topPos;
        }
      }

      if (
        props.rootStore.eclabsStore.searchType === "PARTNUMBER" ||
        props.rootStore.eclabsStore.searchType === "CHECKLIST"
      ) {
        setUsOrigin(true);
        props.rootStore.exportabilityStore.usOrigin = true;
      }
    }
  }, [
    prevSearchDeepSearch,
    selectedEccn,
    props.rootStore.eclabsStore.searchType,
  ]);

  const launchSearch = async () => {
    setPrevSearchDeepSearch("");
    setForcemono(false);
    setGo(false);
    if (wizard == true) props.rootStore.exportabilityStore.usOrigin = true;

    props.rootStore.eclabsStore.eccns = [];
    await props.rootStore.eclabsStore.setSearch(searchInputLocal);

    if (props.rootStore.eclabsStore.searchType === "CHECKLIST") {
      setTimeout(() => {
        if (resultRef.current) {
          if (props.rootStore.eclabsStore.eccns.length !== 0)
            resultRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
        }
      }, 2000);
    }
  };
  const reset = async () => {
    const tenant = props.rootStore.userStore.user?.tenantId;

    // Appel API pour les questions
    const questionsResponse = await fetch(
      `${
        process.env.REACT_APP_SCREEN
      }/get_Question?tenant=${encodeURIComponent(tenant || "")}`
    );
    const questionsData = await questionsResponse.json();

    // Initialisation des Réponses
    const initialReponses = questionsData.map(() => "NON");

    const initialReponsesSousQ = questionsData.map(
      (q: { sous_q: { yes_no: any }[] }) =>
        q.sous_q.map((sq: { yes_no: any }) => ({
          type: sq.yes_no || "",
          reponse: "NON",
        }))
    );

    setQChecklist(questionsData);
    setReponses(initialReponses);
    setReponsesSousQ(initialReponsesSousQ);
    props.rootStore.eclabsStore.eccns = [];
    props.rootStore.eclabsStore.clear();
    setGo(false);
  };

  const prevFromDeep = () => {
    props.rootStore.eclabsStore.setSearch(prevSearchDeepSearch);

    setForcemono(false);

    const elemParent = document.getElementById("result");
    setPrevSearchDeepSearch("");
    if (elemParent) {
      elemParent.scrollTop = 0;
    }
  };

  const handleChangeInputSearch = (event: any) => {
    if (event.target.value !== null) {
      props.rootStore.eclabsStore.search = event.target.value;
      setSearchInput(event.target.value);
    }
  };
  const handleKeyPress = (e: any) => {
    // We pass the new value of the text when calling onAccept
    if (e.key === "Enter") {
      setGo(true);
      launchSearch();
    }
  };

  const deepSearch = (eccnSelect: Eccn) => {
    setPrevSearchDeepSearch(props.rootStore.eclabsStore.search);
    const code = eccnSelect.code.includes(".")
      ? eccnSelect.code.split(".")[0]
      : eccnSelect.code;
    const regulation = eccnSelect.publicListName;

    props.rootStore.eclabsStore.deepSearchEccn(code, regulation);

    if (regulation.substring(0, 2) == "US") setForcemono(true);
  };

  const selectEccn = () => {
    const choiceEccn = selectedEccn;

    if (props.ouClick !== "LoupeNat" && props.ouClick !== "LoupeUS") {
      const selectedEccnus = usEccn(choiceEccn!);
      const eccnuss = {
        eccnCode: selectedEccnus?.code!,
        usOrigin: selectedEccnus?.publicListName.substring(0, 2) == "US",
        publicListName: selectedEccnus?.publicListName,
        keyword: props.rootStore.eclabsStore.search,
        departureCountry: props.rootStore.eclabsStore.countryCode,
      };
props.rootStore.exportabilityStore.setUsEccn(eccnuss!);
      if (selectedEccn) {
        selectedEccn.isUsOrigin =
          selectedEccn?.publicListName.substring(0, 2) == "US";
        //******* a enlever cas de regression usa navigateur */
        if (selectedEccn?.publicListName.substring(0, 2) == "US") {
          setUsOrigin(true);
          props.rootStore.exportabilityStore.usOrigin = true;
        }
      }

      const eccnLists1 = props.rootStore.eclabsStore.eccnLists;
      if (selectedEccn) {
        props.onClose({
          eccnCode: selectedEccn.code,
          usOrigin: selectedEccn.publicListName.substring(0, 2) == "US",
          publicListName: selectedEccn.publicListName,
          keyword: props.rootStore.eclabsStore.search,
          departureCountry: props.rootStore.eclabsStore.countryCode,
        });

        if (props.exportabilityMode) {
          const eccn = {
            eccnCode: selectedEccn.code,
            usOrigin: selectedEccn.publicListName.substring(0, 2) == "US",
            publicListName: selectedEccn.publicListName,
            keyword: props.rootStore.eclabsStore.search,
            departureCountry: props.rootStore.eclabsStore.countryCode,
          };

          if (selectedEccn.isUsOrigin) {
            if (props.ouClick !== "LoupeNat")
              props.rootStore.exportabilityStore.setUsEccn(eccn);
          } else {
            if (props.ouClick !== "LoupeUS") {
              let tmpeccn = eccn;
              tmpeccn.usOrigin = false;
              props.rootStore.exportabilityStore.setNationalEccn(tmpeccn);
            }
            let selectcode = selectedEccn.code;

            if (eccn.publicListName == "DE Munitions list") {
              itarml.map((value) => {
                const tmpcode = eccn.eccnCode.substring(0, 4);
                if (value.code_ml_de == tmpcode) selectcode = value.code_itar;
              });
            }
            if (
              eccn.publicListName == "EU Munitions List" ||
              eccn.publicListName == "FR Munitions list" ||
              eccn.publicListName == "UK Military List" ||
              eccn.publicListName == "ES Munitions list"
            ) {
              itarml.map((value) => {
                const tmpcode = eccn.eccnCode.includes(".")
                  ? eccn.eccnCode.split(".")[0]
                  : eccn.eccnCode;
                if (value.code_ml == tmpcode) selectcode = value.code_itar;
              });
            }
            if (
              eccn.eccnCode === "NC" &&
              props.rootStore.exportabilityStore.usOrigin
            )
              selectcode = "EAR99";

            if (
              eccn.publicListName == "EU Munitions List" ||
              eccn.publicListName == "DE Munitions list" ||
              eccn.publicListName == "FR Munitions list" ||
              eccn.publicListName == "UK Military List" ||
              eccn.publicListName == "ES Munitions list"
            ) {
              if (eccnLists1["US Munitions List (ITAR)"])
                if (eccnLists1["US Munitions List (ITAR)"].length == 1) {
                  let desUs = eccnLists1["US Munitions List (ITAR)"][0];

                  let teccn = eccn;
                  teccn.eccnCode = desUs.code;
                  teccn.usOrigin = Boolean(true);
                  teccn.publicListName = desUs.publicListName;
                  if (props.ouClick !== "LoupeNat") {
                    props.rootStore.exportabilityStore.setUsEccn(teccn);
                    selectedEccn.isUsOrigin = true;
                  }
                }
            }

            noSoloEccn.map((eccntmp, eccntmpIndex) => {
              const eccavecpoint = eccntmp.code + ".";
              if (eccntmp.code == selectcode || eccavecpoint == selectcode) {
                eccn.eccnCode = eccntmp.code;
                eccn.usOrigin = Boolean(true);
                eccn.publicListName = eccntmp.publicListName;

                if (props.ouClick !== "LoupeNat")
                  props.rootStore.exportabilityStore.setUsEccn(eccn);
                selectedEccn.isUsOrigin = true;
              }
            });
          }
        }

        //if (ouClick!=='LoupeNat') props.rootStore.exportabilityStore.setUsOrigin(afficheUs)
      }

      if (selectedEccn?.publicListName == "FR Import (A2)") {
        const screeningDetails1 = [];
        let taz: TrafficLightColor;
        if (selectedEccn.code == "NC Import") {
          taz = "GREEN";
          screeningDetails1.push({
            eccnCode: selectedEccn.code,
            usOrigin: false,
            destinationCountry:
              props.rootStore.eclabsStore.search +
              " \n " +
              selectedEccn.publicListName,
            trafficLightColor: taz,
            message:
              "Ce bien n'est pas controlé pour l'importation au sens de la liste A2. Aucune formalité particulière n'est nécessaire pour l'importation. En revanche, une licence d'exportation peut etre requise si vous souhaitez exporter ce bien.",
          });

          screeningDetails1[0].trafficLightColor = "GREEN";
          props.rootStore.exportabilityStore.screeningCountryResult = {
            departureCountry: { id: 202, name: "FRANCE" },
            countryOfDestination: { id: 0, name: "IMPORT" },
            globalTrafficLightColor: "GREEN",
            screeningDetails: screeningDetails1,
          };
        } else {
          taz = "YELLOW";
          screeningDetails1.push({
            eccnCode: selectedEccn.code,
            usOrigin: false,
            destinationCountry:
              props.rootStore.eclabsStore.search +
              " \n " +
              selectedEccn.publicListName,
            trafficLightColor: taz,
            message:
              "Selon l’article R311-2 du Code de la sécurité intérieure, ce bien est classé " +
              selectedEccn.code,
            moreDetailsLabel:
              "Voir l’ Article R311-2 du Code de la sécurité intérieure, Rubrique 2",
            moreDetailsLink:
              "https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000037908239/",
          });

          screeningDetails1.push({
            eccnCode: "",
            usOrigin: false,
            destinationCountry: "",
            trafficLightColor: taz,
            message:
              "Pour la Fabrication, le Commerce et l’Intermédiation d’un bien classé dans la catégorie A2, une autorisation (AFCI) est nécessaire en application de l’article R. 2332-5 du code de la défense. ",
            moreDetailsLabel: "Voir l’Article R. 2332-5 du code de la défense",
            moreDetailsLink:
              "https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000037908079/2019-07-01",
          });

          screeningDetails1.push({
            eccnCode: "",
            usOrigin: false,
            destinationCountry: "",
            trafficLightColor: taz,
            message:
              "Pour l’importation, une Autorisation d’Importation de Matériel de Guerre (AIMG) est nécessaire en application de l’article R. 2335-1 du code de la défense ;",
            moreDetailsLabel: "Voir l’Article R. 2335-1 du code de la défense",
            moreDetailsLink:
              "https://www.legifrance.gouv.fr/codes/id/LEGIARTI000041531402/2020-02-01/",
          });

          screeningDetails1[0].trafficLightColor = "YELLOW";
          props.rootStore.exportabilityStore.screeningCountryResult = {
            departureCountry: { id: 202, name: "FRANCE" },
            countryOfDestination: { id: 0, name: "IMPORT" },
            globalTrafficLightColor: "YELLOW",
            screeningDetails: screeningDetails1,
          };
        }
        props.rootStore.exportabilityStore.setCountryState("DONE");
        if (wizard) {
          props.rootStore.exportabilityStore.usOrigin = true;
        }

        navigate("/exportability/results");
      } else {
       if ((selectedEccnus?.code.toLowerCase() === "no us content".toLowerCase())|| (selectedEccnus?.code.toLowerCase() === "Not subject to the EAR".toLowerCase())) {
          props.rootStore.exportabilityStore.setUsEccn(undefined);
          props.rootStore.exportabilityStore.setUsOrigin(false);
        }
      }
      navigate("/exportability");
    }
  };

  const usEccn = (frEccn: Eccn) => {
    const eccnLists1 = props.rootStore.eclabsStore.eccnLists;
    // console.log(eccnLists1)
    if (frEccn.publicListName === "EU Munitions List") {
      if (frEccn.code[frEccn.code.length - 1] === ".")
        frEccn.code = frEccn.code.slice(0, -1);
    }

    var desUs = frEccn;
    if (
      props.rootStore.eclabsStore.searchType === "CHECKLIST" ||
      (search_templete == 1 &&
        props.rootStore.eclabsStore.searchType !== "KEYWORD")
    ) {
      if (eccnLists1[""])
        eccnLists1[""].map((eccntmp, eccntmpIndex) => {
          if (frEccn.type == "PR") {
            if (eccntmp.isUsOrigin) {
              desUs = eccntmp;
              //  alert(eccntmp.code);
              if (noSoloEccn.indexOf(desUs) === -1)
                setNoSoloEccn([...noSoloEccn, desUs]);
            }
          } else {
            if (eccntmp.type == "PR") {
              desUs = eccntmp;
              if (noSoloEccn.indexOf(desUs) === -1)
                setNoSoloEccn([...noSoloEccn, desUs]);
            }
          }
        });

      if (eccnLists1["US Dual-Use List (EAR)"])
        eccnLists1["US Dual-Use List (EAR)"].map((eccntmp, eccntmpIndex) => {
          if (frEccn.type == "PR") {
            //alert(frEccn.code + "<==>"+eccntmp.code);
            if (
              eccntmp.code == frEccn.code ||
              eccnLists1["US Dual-Use List (EAR)"].length == 1
            ) {
              desUs = eccntmp;
              if (noSoloEccn.indexOf(desUs) === -1)
                setNoSoloEccn([...noSoloEccn, desUs]);
            }
          }
          if (frEccn.related_codes == eccntmp.related_codes) {
            if (props.rootStore.eclabsStore.intangible) {
              if (
                eccntmp.code.replace(/\./g, "") == frEccn.code ||
                eccntmp.code == frEccn.code ||
                eccntmp.code + "." == frEccn.code
              ) {
                desUs = eccntmp;
                if (noSoloEccn.indexOf(desUs) === -1) {
                  setNoSoloEccn([...noSoloEccn, desUs]);
                }
              }
            } else {
              desUs = eccntmp;
              if (noSoloEccn.indexOf(desUs) === -1) {
                setNoSoloEccn([...noSoloEccn, desUs]);
              }
            }
          }
        });
      if (eccnLists1["US Munitions List (ITAR)"]) {
        eccnLists1["US Munitions List (ITAR)"].map((eccntmp, eccntmpIndex) => {
          if (frEccn.type == "PR") {
            desUs = eccntmp;
            if (noSoloEccn.indexOf(desUs) === -1) {
              setNoSoloEccn([...noSoloEccn, desUs]);
            }
          }
        });
      }
    }

    if (
      props.rootStore.eclabsStore.searchType === "PARTNUMBER" ||
      props.rootStore.eclabsStore.searchType === "CHECKLIST"
    ) {
      if (eccnLists1["US Dual-Use List (EAR)"])
        eccnLists1["US Dual-Use List (EAR)"].map((eccntmp, eccntmpIndex) => {
          if (
            frEccn.related_codes &&
            eccntmp.related_codes &&
            frEccn.related_codes.split("$")[0] ==
              eccntmp.related_codes.split("$")[0]
          ) {
            if (
              props.rootStore.eclabsStore.intangible &&
              // &&props.rootStore.eclabsStore.searchType === "CHECKLIST"
              ((eccnLists1["FR Liste Double Usage"] &&
                eccnLists1["FR Liste Double Usage"].length != 1) ||
                (eccnLists1["EU Dual-Use List"] &&
                  eccnLists1["EU Dual-Use List"].length != 1))
            ) {
              if (
                eccntmp.code.replace(/\./g, "") == frEccn.code ||
                eccntmp.code == frEccn.code ||
                eccntmp.code + "." == frEccn.code
              ) {
                desUs = eccntmp;
                if (noSoloEccn.indexOf(desUs) === -1) {
                  setNoSoloEccn([...noSoloEccn, desUs]);
                }
              }
            } else {
              desUs = eccntmp;

              if (noSoloEccn.indexOf(desUs) === -1) {
                setNoSoloEccn([...noSoloEccn, desUs]);
              }
            }
          }
        });

      if (eccnLists1["US Munitions List (ITAR)"])
        eccnLists1["US Munitions List (ITAR)"].map((eccntmp, eccntmpIndex) => {
          if (frEccn.related_codes && eccntmp.related_codes) {
            if (
              frEccn.related_codes.split("$")[0] ==
              eccntmp.related_codes.split("$")[0]
            ) {
              desUs = eccntmp;

              if (noSoloEccn.indexOf(desUs) === -1) {
                setNoSoloEccn([...noSoloEccn, desUs]);
              }
            }
          }
        });
      if (desUs != frEccn) {
        return desUs;
      }
    } else {
      if (eccnLists1["US Dual-Use List (EAR)"])
        eccnLists1["US Dual-Use List (EAR)"].map((eccntmp, eccntmpIndex) => {
          if (
            (eccntmp.code.replace(/\./g, "") == frEccn.code ||
              eccntmp.code == frEccn.code ||
              eccntmp.code + "." == frEccn.code) &&
            frEccn.publicListName !== "US Dual-Use List (EAR)"
          ) {
            desUs = eccntmp;

            if (noSoloEccn.indexOf(desUs) === -1) {
              setNoSoloEccn([...noSoloEccn, desUs]);
            }
          }
        });

      if (
        frEccn.publicListName === "EU Munitions List" ||
        frEccn.publicListName === "FR Munitions list" ||
        frEccn.publicListName === "UK Military List" ||
        frEccn.publicListName === "ES Munitions list"
      ) {
        itarml.map((value) => {
          const tmpcode = frEccn.code.includes(".")
            ? frEccn.code.split(".")[0]
            : frEccn.code;
          if (value.code_ml == tmpcode) {
            if (eccnLists1["US Munitions List (ITAR)"])
              eccnLists1["US Munitions List (ITAR)"].map(
                (eccntmp, eccntmpIndex) => {
                  const tmpcode_eccn = eccntmp.code;
                  if (
                    tmpcode_eccn == value.code_itar &&
                    ((eccntmp.code.includes("(") &&
                      frEccn.code.includes(".")) ||
                      (!frEccn.code.includes(".") &&
                        !eccntmp.code.includes("(")))
                  ) {
                    desUs = eccntmp;
                    if (noSoloEccn.indexOf(desUs) === -1) {
                      setNoSoloEccn([...noSoloEccn, desUs]);
                    }
                  }
                }
              );
          }
        });
      }

      if (frEccn.publicListName == "DE Munitions list") {
        itarml.map((value) => {
          const tmpcode = frEccn.code.substring(0, 4);
          if (value.code_ml_de == tmpcode) {
            if (eccnLists1["US Munitions List (ITAR)"])
              eccnLists1["US Munitions List (ITAR)"].map(
                (eccntmp, eccntmpIndex) => {
                  const tmpcode_eccn = eccntmp.code.includes("(")
                    ? eccntmp.code.split("(")[0]
                    : eccntmp.code;

                  if (
                    tmpcode_eccn == value.code_itar &&
                    ((eccntmp.code.includes("(") &&
                      /[a-zA-Z]/.test(frEccn.code)) ||
                      (!/[a-zA-Z]/.test(frEccn.code) &&
                        !eccntmp.code.includes("(")))
                  ) {
                    desUs = eccntmp;
                    if (noSoloEccn.indexOf(desUs) === -1) {
                      setNoSoloEccn([...noSoloEccn, desUs]);
                    }
                  }
                }
              );
          }
        });
      }

      if (
        frEccn.publicListName == "EU Munitions List" ||
        frEccn.publicListName == "DE Munitions list" ||
        frEccn.publicListName == "FR Munitions list" ||
        frEccn.publicListName == "UK Military List" ||
        frEccn.publicListName == "ES Munitions list"
      ) {
        if (eccnLists1["US Munitions List (ITAR)"])
          if (eccnLists1["US Munitions List (ITAR)"].length == 1) {
            desUs = eccnLists1["US Munitions List (ITAR)"][0];
            if (noSoloEccn.indexOf(desUs) === -1) {
              setNoSoloEccn([...noSoloEccn, desUs]);
            }
          }
      }

      //********** voir cas 6 unique */
      let unique6 = 0;
      let codeunique6 = desUs;
      let sous_codeunique6 = desUs;
      if (eccnLists1["US Dual-Use List (EAR)"])
        eccnLists1["US Dual-Use List (EAR)"].map((eccntmp, eccntmpIndex) => {
          if (eccntmp.code.charAt(2) === "6" && !eccntmp.code.includes(".")) {
            unique6++;
            codeunique6 = eccntmp;
          }

          if (eccntmp.code.charAt(2) === "6" && eccntmp.code.includes("."))
            sous_codeunique6 = eccntmp;
        });

      if (desUs != frEccn) unique6 = 0;

      if (
        frEccn.publicListName == "EU Munitions List" ||
        frEccn.publicListName == "DE Munitions list" ||
        frEccn.publicListName == "FR Munitions list" ||
        frEccn.publicListName == "UK Military List" ||
        frEccn.publicListName == "ES Munitions list"
      ) {
        if (unique6 == 1) {
          if (frEccn.code.includes(".")) desUs = sous_codeunique6;
          else desUs = codeunique6;
          if (noSoloEccn.indexOf(desUs) === -1) {
            setNoSoloEccn([...noSoloEccn, desUs]);
          }
        }
      }

      if (frEccn.code === "NC" || frEccn.code === "NEC")
        if (eccnLists1["US Dual-Use List (EAR)"])
          eccnLists1["US Dual-Use List (EAR)"].map((eccntmp, eccntmpIndex) => {
            if (eccntmp.code === "EAR99") {
              desUs = eccntmp;
              if (noSoloEccn.indexOf(desUs) === -1) {
                setNoSoloEccn([...noSoloEccn, desUs]);
              }
            }
          });
      if (desUs != frEccn) {
        return desUs;
      }
    }
  };

  const convertpay = (s: string) => {
    if (s === "FR") return i18n.t("global.countries.FR");
    if (s === "BE") return i18n.t("global.countries.BE");
    if (s === "SG") return i18n.t("global.countries.SG");
    if (s === "UK") return i18n.t("global.countries.UK");
    if (s === "ES") return i18n.t("global.countries.SP");
    if (s === "IT") return i18n.t("global.countries.IT");
    if (s === "DK") return i18n.t("global.countries.DK");
    if (s === "PT") return i18n.t("global.countries.PT");
    if (s === "CH") return i18n.t("global.countries.CH");
    if (s === "NE") return i18n.t("global.countries.EU1");
    if (s === "SE") return i18n.t("global.countries.EU2");
    if (s === "NL") return i18n.t("global.countries.EU3");
    if (s === "ZA") return i18n.t("global.countries.EU4");
    if (s === "US") return i18n.t("global.countries.US");
    if (s === "TW") return i18n.t("global.countries.TW");
    if (s === "KR") return i18n.t("global.countries.KR");
    if (s === "DE") return i18n.t("global.countries.DE");
    if (s === "CA") return i18n.t("global.countries.CA");

    return s;
  };

  const renderEccn = () => {
    let eccnLists: { [key: string]: Eccn[] };
    let pays_temp = "";
    eccnLists = props.rootStore.eclabsStore.eccnLists;
   

    // console.log(eccnLists)
    //console.log(props.rootStore.eclabsStore.eccns[0])
    return (
      <div id="print">
        {props.rootStore.eclabsStore.searchType === "CHECKLIST" && (
          <FormGroup>
            {Q_checklist.map((question, index) => (
              <Grid
                container
                alignItems="center"
                spacing={2}
                justify="space-between"
                key={index}
              >
                <Grid item xs={10}>
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: question.libelle!.replace(/\n/g, "<br />"),
                    }}
                  ></Typography>
                </Grid>
                <Grid item xs={2}>
                  <RadioGroup
                    aria-label={question.libelle}
                    name={question.libelle}
                    row
                    value={Reponses[index]}
                    onChange={(e) => handleChangequestion(e, index)}
                  >
                    <FormControlLabel
                      value="OUI"
                      control={<Radio color="primary" size="small" />}
                      label={i18n.t(
                        "pages.admin.tenants.table.wizard.true-value"
                      )}
                    />
                    <FormControlLabel
                      value="NON"
                      control={<Radio color="primary" size="small" />}
                      label={i18n.t(
                        "pages.admin.tenants.table.wizard.false-value"
                      )}
                    />
                  </RadioGroup>
                </Grid>
                {question.sous_q &&
                  question.sous_q.map(
                    (sous_q, sous_q_index) =>
                      ((Reponses[index] === "OUI" && sous_q.yes_no === "Y") ||
                        (Reponses[index] === "NON" &&
                          sous_q.yes_no === "N")) && (
                        <Grid
                          container
                          style={{ marginLeft: "20px" }}
                          key={sous_q_index}
                        >
                          <Grid item xs={10}>
                            <Typography
                              variant="body2"
                              style={{ paddingTop: "12px" }}
                              dangerouslySetInnerHTML={{
                                __html: sous_q.libelle!.replace(
                                  /\n/g,
                                  "<br />"
                                ),
                              }}
                            ></Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="body2">
                              <RadioGroup
                                aria-label={sous_q.libelle}
                                name={sous_q.libelle}
                                row
                                value={
                                  ReponsesSousQ[index][sous_q_index].reponse
                                }
                                onChange={(e) =>
                                  handleChangeSousQ(
                                    e,
                                    index,
                                    sous_q_index,
                                    sous_q.yes_no || ""
                                  )
                                }
                              >
                                <FormControlLabel
                                  value="OUI"
                                  control={
                                    <Radio color="primary" size="small" />
                                  }
                                  label={
                                    <Typography variant="body2">
                                      {i18n.t(
                                        "pages.admin.tenants.table.wizard.true-value"
                                      )}
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  value="NON"
                                  control={
                                    <Radio color="primary" size="small" />
                                  }
                                  label={
                                    <Typography variant="body2">
                                      {" "}
                                      {i18n.t(
                                        "pages.admin.tenants.table.wizard.false-value"
                                      )}
                                    </Typography>
                                  }
                                />
                              </RadioGroup>
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                  )}
              </Grid>
            ))}
            <br />
            {props.rootStore.eclabsStore.eccns.length > 0 &&
              search_templete !== 1 &&
              (props.rootStore.eclabsStore.eccns[0].HS_check == true ||
                props.rootStore.eclabsStore.eccns[0].HTS_check == true) && (
                <table>
                  <tr>
                    <td style={{ width: "3%" }}></td>
                    <td style={{ width: "30%" }}>
                      <Typography variant="body1">
                        <b>{i18n.t("pages.exportability.national-eccn")} </b>
                      </Typography>
                    </td>
                    <td style={{ width: "7%" }}></td>
                    <td style={{ width: "30%" }}>
                      <Typography variant="body1">
                        <b>{i18n.t("pages.exportability.us-eccn")}</b>
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "3%" }}></td>
                    <td style={{ width: "30%" }}>
                      {props.rootStore.eclabsStore.eccns[0].HS_check ==
                        true && (
                        <Typography variant="body2">
                          EU HS code :{" "}
                          {props.rootStore.eclabsStore.eccns[0].hs_code}
                        </Typography>
                      )}
                    </td>
                    <td style={{ width: "7%" }}></td>
                    <td style={{ width: "30%" }}>
                      {props.rootStore.eclabsStore.eccns[0].HTS_check ==
                        true && (
                        <Typography variant="body2">
                          US HTS code :{" "}
                          {props.rootStore.eclabsStore.eccns[0].hts_code}
                        </Typography>
                      )}
                    </td>
                  </tr>
                </table>
              )}
            {props.rootStore.eclabsStore.eccns.length > 0 &&
              props.rootStore.eclabsStore.eccns[0].HS_check == false &&
              props.rootStore.eclabsStore.eccns[0].HTS_check == false && (
                <table>
                  <tr>
                    <td style={{ width: "3%" }}></td>
                    <td style={{ width: "30%" }}>
                      <Typography variant="body1">
                        <b>{i18n.t("pages.exportability.national-eccn")} </b>
                      </Typography>
                    </td>
                    <td style={{ width: "7%" }}></td>
                    <td style={{ width: "30%" }}>
                      <Typography variant="body1">
                        <b>{i18n.t("pages.exportability.us-eccn")}</b>
                      </Typography>
                    </td>
                  </tr>
                </table>
              )}
          </FormGroup>
        )}

        {props.rootStore.eclabsStore.eccns.length > 0 &&
          search_templete === 1 &&
          props.rootStore.eclabsStore.searchType !== "KEYWORD" &&
          (props.rootStore.eclabsStore.eccns[0].HS_check == true ||
            props.rootStore.eclabsStore.eccns[0].HTS_check == true) && (
            <table>
              <tr>
                <td style={{ width: "3%" }}></td>
                <td style={{ width: "30%" }}>
                  <Typography variant="body1">
                    <b>{i18n.t("pages.exportability.national-eccn")} </b>
                  </Typography>
                </td>
                <td style={{ width: "7%" }}></td>
                <td style={{ width: "30%" }}>
                  <Typography variant="body1">
                    <b>{i18n.t("pages.exportability.us-eccn")}</b>
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ width: "3%" }}></td>
                <td style={{ width: "30%" }}>
                  {props.rootStore.eclabsStore.eccns[0].HS_check == true && (
                    <Typography variant="body2">
                      EU HS code :{" "}
                      {props.rootStore.eclabsStore.eccns[0].hs_code}
                    </Typography>
                  )}
                </td>
                <td style={{ width: "7%" }}></td>
                <td style={{ width: "30%" }}>
                  {props.rootStore.eclabsStore.eccns[0].HTS_check == true && (
                    <Typography variant="body2">
                      US HTS code :{" "}
                      {props.rootStore.eclabsStore.eccns[0].hts_code}
                    </Typography>
                  )}
                </td>
              </tr>
            </table>
          )}
        {props.rootStore.eclabsStore.eccns.length > 0 &&
          props.rootStore.eclabsStore.searchType !== "CHECKLIST" &&
          props.rootStore.eclabsStore.eccns[0].HS_check == false &&
          props.rootStore.eclabsStore.eccns[0].HTS_check == false && (
            <table>
              <tr>
                <td style={{ width: "3%" }}></td>
                <td style={{ width: "30%" }}>
                  <Typography variant="body1">
                    <b>{i18n.t("pages.exportability.national-eccn")} </b>
                  </Typography>
                </td>
                <td style={{ width: "7%" }}></td>
                <td style={{ width: "30%" }}>
                  <Typography variant="body1">
                    <b>{i18n.t("pages.exportability.us-eccn")}</b>
                  </Typography>
                </td>
              </tr>
            </table>
          )}
        {!(
          props.rootStore.eclabsStore.eccns.length === 0 &&
          props.rootStore.eclabsStore.loading === false
        ) && (
          <div id="result" className={styles.eccns}>
            {props.rootStore.eclabsStore.synonym !== undefined &&
              props.rootStore.eclabsStore.synonym.toString().includes("$") &&
              props.rootStore.eclabsStore.searchType === "KEYWORD" && (
                <React.Fragment>
                  <div>
                    {i18n.t("errors.eccn-ref1")}
                    {i18n.t("errors.eccn-ref2")}"
                    <b>
                      {props.rootStore.eclabsStore.synonym.replace("$", "")}
                    </b>
                    "
                  </div>
                  <br />
                </React.Fragment>
              )}

            {!(
              props.rootStore.eclabsStore.eccns.length === 0 &&
              props.rootStore.eclabsStore.loading === false
            ) &&
              props.rootStore.eclabsStore.synonym !== undefined &&
              props.rootStore.eclabsStore.synonym.toString().includes("§") && (
                <div id="result" className={styles.eccns}>
                  <div>
                    {" "}
                    {i18n.t("errors.eccn-douanier1")}{" "}
                    {props.rootStore.eclabsStore.search}{" "}
                  </div>
                  <div>
                    {" "}
                    {i18n.t("errors.eccn-douanier2")}{" "}
                    {props.rootStore.eclabsStore.search.substring(0, 6)}{" "}
                  </div>
                </div>
              )}

            {
              //*************************Bloc searchType === "PARTNUMBER" or !NC */

              Object.keys(eccnLists).map((list, listIndex) => {
                return (
                  <div
                    key={listIndex}
                    style={{
                      display: checkbox
                        ? list == "FR Import (A2)"
                          ? "block"
                          : "none"
                        : list != "FR Import (A2)"
                          ? "block"
                          : "none",
                    }}
                  >
                    {eccnLists[list] &&
                      eccnLists[list][0] &&
                      eccnLists[list][0].designation && (
                        <p>{eccnLists[list][0].designation}</p>
                      )}

                    {eccnLists[list].map((eccn, eccnIndex) => {
                      // console.log(eccn);
                      if (
                        eccn.code !== "NC" ||
                        props.rootStore.eclabsStore.searchType === "PARTNUMBER"
                      ) {
                        const eccnus = usEccn(eccn);

                        let tmpeccn = eccn.isUsOrigin;
                        if ("FR" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if ("UK" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if ("EU" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if ("DE" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if ("ES" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if ("EU" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if (eccn.type == "PR") tmpeccn = false;
                        if (
                          props.rootStore.eclabsStore.countryCode == "US" ||
                          forcemono ||
                          (props.rootStore.eclabsStore.searchType ===
                            "PARTNUMBER" &&
                            props.rootStore.eclabsStore.countryCode === "ALL" &&
                            eccn.related_codes &&
                            eccn.related_codes.slice(-2) === "US")
                        )
                          return (
                            <React.Fragment>
                              {props.rootStore.eclabsStore.searchType ===
                                "PARTNUMBER" &&
                                props.rootStore.eclabsStore.countryCode ===
                                  "ALL" &&
                                pays_temp !== eccn.related_codes.slice(-2) && (
                                  <div style={{ fontSize: "20px" }}>
                                    <b>
                                      {convertpay(
                                        (pays_temp =
                                          eccn.related_codes.slice(-2))
                                      )}
                                    </b>
                                  </div>
                                )}
                              <div>
                                {eccn.related_codes &&
                                  eccn.related_codes.slice(0, -3)}
                              </div>

                              <EccnComponentOrigine
                                onClick={(selectedEccn) =>
                                  setSelectedEccn(selectedEccn)
                                }
                                onCodeClick={(selectedEccn) =>
                                  deepSearch(selectedEccn)
                                }
                                search={props.rootStore.eclabsStore.search}
                                synonym={props.rootStore.eclabsStore.synonym}
                                searchType={
                                  props.rootStore.eclabsStore.searchType
                                }
                                key={eccnIndex}
                                element={eccn}
                                seeDetails={!prevSearchDeepSearch}
                                selected={
                                  selectedEccn &&
                                  selectedEccn.code === eccn.code &&
                                  selectedEccn.publicListName ===
                                    eccn.publicListName
                                }
                              />
                            </React.Fragment>
                          );
                        else if (
                          props.ouClick != "" &&
                          props.ouClick != "home"
                        ) {
                          if (
                            (props.ouClick === "LoupeNat" && !tmpeccn) ||
                            (props.ouClick === "LoupeUS" && tmpeccn) ||
                            forcemono
                          )
                            return (
                              <React.Fragment>
                                <div>{eccn.related_codes}</div>

                                <EccnComponentOrigine
                                  onClick={(selectedEccn) =>
                                    setSelectedEccn(selectedEccn)
                                  }
                                  onCodeClick={(selectedEccn) =>
                                    deepSearch(selectedEccn)
                                  }
                                  search={props.rootStore.eclabsStore.search}
                                  synonym={props.rootStore.eclabsStore.synonym}
                                  key={eccnIndex}
                                  element={eccn}
                                  seeDetails={!prevSearchDeepSearch}
                                  searchType={
                                    props.rootStore.eclabsStore.searchType
                                  }
                                  selected={
                                    selectedEccn &&
                                    selectedEccn.code === eccn.code &&
                                    selectedEccn.publicListName ===
                                      eccn.publicListName
                                  }
                                />
                              </React.Fragment>
                            );
                        } else {
                          if (
                            (noSoloEccn.indexOf(eccn) === -1 &&
                              props.rootStore.eclabsStore.uniqueus === "OK") ||
                            noSoloEccn.indexOf(eccn) === -1 ||
                            props.rootStore.eclabsStore.uniqueusparam ===
                              "OK" ||
                            (eccn.type === "PR" &&
                              eccnus?.code !== "PR" &&
                              !eccn.isUsOrigin &&
                              !(
                                eccn.related_codes &&
                                eccn.related_codes.includes("V5331032120400") &&
                                eccn.publicListName ==
                                  "US Dual-Use List (EAR)" &&
                                eccn.code == "EAR99"
                              ) &&
                              !(
                                eccn.related_codes ==
                                  "M640-0003ABNHI-AAUB4-A2S - MicroCube 640 - HS code : 9027.50.00" &&
                                eccn.publicListName ==
                                  "US Dual-Use List (EAR)" &&
                                eccn.code == "6A003.b.4.b"
                              ) &&
                              !(
                                eccn.publicListName ==
                                  "US Dual-Use List (EAR)" &&
                                eccn.code == "1C202.b"
                              ))
                          )
                            return (
                              <React.Fragment>
                                {props.rootStore.eclabsStore.searchType ===
                                  "PARTNUMBER" &&
                                  props.rootStore.eclabsStore.countryCode ===
                                    "ALL" &&
                                  pays_temp !==
                                    eccn.related_codes.slice(-2) && (
                                    <div style={{ fontSize: "20px" }}>
                                      <b>
                                        {convertpay(
                                          (pays_temp =
                                            eccn.related_codes.slice(-2))
                                        )}
                                      </b>
                                    </div>
                                  )}
                                <div>
                                  {eccn.related_codes &&
                                    eccn.related_codes.slice(0, -3)}
                                </div>
                                <EccnComponent
                                  onClick={(selectedEccn) =>
                                    setSelectedEccn(selectedEccn)
                                  }
                                  onCodeClick={(selectedEccn) =>
                                    deepSearch(selectedEccn)
                                  }
                                  type={
                                    search_templete === 1 &&
                                    props.rootStore.eclabsStore.searchType !==
                                      "KEYWORD"
                                      ? "CHECKLIST"
                                      : props.rootStore.eclabsStore.searchType
                                  }
                                  search={props.rootStore.eclabsStore.search}
                                  synonym={props.rootStore.eclabsStore.synonym}
                                  country={
                                    props.rootStore.eclabsStore.countryCode
                                  }
                                  key={eccnIndex}
                                  element={eccn}
                                  elementus={eccnus}
                                  afficheUs={
                                    props.rootStore.exportabilityStore
                                      .usOrigin ||
                                    props.rootStore.eclabsStore.searchType ===
                                      "PARTNUMBER"
                                  }
                                  seeDetails={!prevSearchDeepSearch}
                                  selected={
                                    selectedEccn &&
                                    selectedEccn.code === eccn.code &&
                                    selectedEccn.publicListName ===
                                      eccn.publicListName &&
                                    selectedEccn.related_codes ===
                                      eccn.related_codes
                                  }
                                />
                              </React.Fragment>
                            );
                        }
                      }
                    })}
                  </div>
                );
              })
            }

            {
              //*************************Bloc searchType !== "PARTNUMBER" */

              Object.keys(eccnLists).map((list, listIndex) => {
                return (
                  <div
                    key={listIndex}
                    style={{
                      display: checkbox
                        ? list == "FR Import (A2)"
                          ? "block"
                          : "none"
                        : list != "FR Import (A2)"
                          ? "block"
                          : "none",
                    }}
                  >
                    {eccnLists[list] &&
                      eccnLists[list][0] &&
                      eccnLists[list][0].designation && (
                        <p>{eccnLists[list][0].designation}</p>
                      )}

                    {eccnLists[list].map((eccn, eccnIndex) => {
                      if (
                        (eccn.code === "NC" || eccn.code === "NEC") &&
                        props.rootStore.eclabsStore.searchType !== "PARTNUMBER"
                      ) {
                        const eccnus = usEccn(eccn);

                        let tmpeccn = eccn.isUsOrigin;
                        if ("FR" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if ("UK" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if ("EU" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if ("DE" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if ("ES" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if ("EU" === eccn.publicListName.substring(0, 2))
                          tmpeccn = false;
                        if (eccn.type == "PR") tmpeccn = false;
                        if (props.rootStore.eclabsStore.countryCode == "US")
                          return (
                            <React.Fragment>
                              <div>{eccn.related_codes}</div>

                              <EccnComponentOrigine
                                onClick={(selectedEccn) =>
                                  setSelectedEccn(selectedEccn)
                                }
                                onCodeClick={(selectedEccn) =>
                                  deepSearch(selectedEccn)
                                }
                                search={props.rootStore.eclabsStore.search}
                                synonym={props.rootStore.eclabsStore.synonym}
                                key={eccnIndex}
                                element={eccn}
                                seeDetails={!prevSearchDeepSearch}
                                searchType={
                                  props.rootStore.eclabsStore.searchType
                                }
                                selected={
                                  selectedEccn &&
                                  selectedEccn.code === eccn.code &&
                                  selectedEccn.publicListName ===
                                    eccn.publicListName
                                }
                              />
                            </React.Fragment>
                          );
                        else if (
                          props.ouClick != "" &&
                          props.ouClick != "home"
                        ) {
                          if (
                            (props.ouClick === "LoupeNat" && !tmpeccn) ||
                            (props.ouClick === "LoupeUS" && tmpeccn)
                          )
                            return (
                              <React.Fragment>
                                <div>{eccn.related_codes}</div>

                                <EccnComponentOrigine
                                  onClick={(selectedEccn) =>
                                    setSelectedEccn(selectedEccn)
                                  }
                                  onCodeClick={(selectedEccn) =>
                                    deepSearch(selectedEccn)
                                  }
                                  search={props.rootStore.eclabsStore.search}
                                  synonym={props.rootStore.eclabsStore.synonym}
                                  key={eccnIndex}
                                  element={eccn}
                                  searchType={
                                    props.rootStore.eclabsStore.searchType
                                  }
                                  seeDetails={!prevSearchDeepSearch}
                                  selected={
                                    selectedEccn &&
                                    selectedEccn.code === eccn.code &&
                                    selectedEccn.publicListName ===
                                      eccn.publicListName
                                  }
                                />
                              </React.Fragment>
                            );
                        } else if (noSoloEccn.indexOf(eccn) === -1)
                          return (
                            <React.Fragment>
                              <div>{eccn.related_codes}</div>
                              <EccnComponent
                                onClick={(selectedEccn) =>
                                  setSelectedEccn(selectedEccn)
                                }
                                onCodeClick={(selectedEccn) =>
                                  deepSearch(selectedEccn)
                                }
                                key={eccnIndex}
                                type={
                                  search_templete === 1 &&
                                  props.rootStore.eclabsStore.searchType !==
                                    "KEYWORD"
                                    ? "CHECKLIST"
                                    : props.rootStore.eclabsStore.searchType
                                }
                                search={props.rootStore.eclabsStore.search}
                                synonym={props.rootStore.eclabsStore.synonym}
                                country={
                                  props.rootStore.eclabsStore.countryCode
                                }
                                element={eccn}
                                elementus={eccnus}
                                afficheUs={
                                  props.rootStore.exportabilityStore.usOrigin
                                }
                                seeDetails={!prevSearchDeepSearch}
                                selected={
                                  selectedEccn &&
                                  selectedEccn.code === eccn.code &&
                                  selectedEccn.publicListName ===
                                    eccn.publicListName &&
                                  selectedEccn.related_codes ===
                                    eccn.related_codes
                                }
                              />
                            </React.Fragment>
                          );
                      }
                    })}
                  </div>
                );
              })
            }
          </div>
        )}
      </div>
    );
  };

  const handleDownloadImage = async () => {
    //const element = document.getElementById('print')!

    const auteur =
      props.rootStore.userStore.user?.firstname +
      " " +
      props.rootStore.userStore.user?.lastname;
    const societe = props.rootStore.userStore.user?.tenantId;
    const textelibre = textlibre;

    const choiceEccn = selectedEccn;
    const selectedEccnus = usEccn(choiceEccn!);

    //alert(selectedEccnus?.publicListName!)

    //******************traitement sous code */

    const code = choiceEccn?.code.includes(".")
      ? choiceEccn.code.split(".")[0]
      : choiceEccn?.code;
    const regulation = choiceEccn?.publicListName;

    //props.rootStore.eclabsStore.deepSearchEccn(code!, regulation!)
    let codenc = choiceEccn?.code;
    const maDate = new Date();
    var afDate = moment(maDate).format("YYYY-MM-DD");

    var doc = new jsPDF("p", "mm");
    let country = window.navigator.language;

    //alert(country)
    let datrap = moment().locale("en").format("ll");
    if (country === "fr-FR" || country === "fr")
      datrap = moment().locale("fr").format("ll");

    // doc.setFont('Arial');
    doc.setFontSize(14);

    let width = doc.internal.pageSize.getWidth();
    let titre_global = i18n.t("global.classrapport");

    if (titre_modal) titre_global = titre_modal_text;

    //doc.text(titre_global, doc.internal.pageSize.getWidth() / 2 - 20, 10);

    const lines = titre_global.split("\n");
    lines.forEach((line, index) => {
      const lineWidth = doc.getStringUnitWidth(line) * 2;
      doc.text(
        line,
        (doc.internal.pageSize.getWidth() - lineWidth) / 2 - 20,
        10 + 5 * index
      );
    });

    doc.setFontSize(10);
    let multititre = 0;

    //titre du haut
    if (titre_global.includes("\n") || titre_global.includes("\r\n"))
      multititre = 10;

    doc.text(i18n.t("global.rapport") + " " + datrap, 20, 20 + multititre);
    doc.text(i18n.t("global.autheur") + " : " + auteur, 20, 25 + multititre);
    doc.text(i18n.t("global.societe") + " : " + societe, 20, 30 + multititre);
    //let ligne=Array()
    let i = 0;
    let pas = 80;
    let ligne = "";
    //for (i=0;i<30;i++)ligne[i]=""

    if (props.rootStore.eclabsStore.search != "")
      ligne =
        i18n.t("global.expression_recherche") +
        " : " +
        String(props.rootStore.eclabsStore.search);

    if (
      props.rootStore.eclabsStore.searchType === "PARTNUMBER" ||
      props.rootStore.eclabsStore.searchType === "CHECKLIST"
    )
      if (props.rootStore.eclabsStore.searchType === "PARTNUMBER")
        ligne =
          "Part number " + " : " + String(props.rootStore.eclabsStore.search);
    doc.text(ligne, 20, pas);
    pas = pas + 5;

    doc.text("", 20, pas);
    pas = pas + 5;

    doc.setFont("", "bold");

    ligne = i18n.t("global.cn");
    if (choiceEccn?.isUsOrigin) ligne = "U.S. classification";

    doc.text(ligne, 20, pas);
    pas = pas + 5;
    doc.setFont("", "normal");

    doc.text("", 20, pas);
    pas = pas + 5;

    if (choiceEccn?.code !== "NC") {
      let eccns_parent: Eccn[] = [];
      try {
        eccns_parent = await api.deepSearchEccn(code!, regulation!);
      } catch (error) {}
      if (!selectedEccn?.isUsOrigin) {
        if (selectedEccn?.type === "DU") {
          ligne = i18n.t("global.usagedu1");
          doc.text(ligne, 20, pas);
          pas = pas + 5;

          ligne = i18n.t("global.usagedu2");
          doc.text(ligne, 20, pas);
          pas = pas + 5;
        }
        if (selectedEccn?.type === "ML") {
          ligne = String(i18n.t("global.usageml1"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;

          ligne = i18n.t("global.usageml2");
          //doc.text( ligne,20,pas)
          //pas=pas+5

          // la petite demande annex au cdc
          //ligne=""
          if (
            country !== "fr-FR" &&
            country !== "fr" &&
            props.rootStore.eclabsStore.countryCode === "FR"
          )
            ligne =
              "Applicable regulation : Arrêté du 27 juin 2012 (French Munitions List)";
          if (
            (country === "fr-FR" || country === "fr") &&
            props.rootStore.eclabsStore.countryCode === "FR"
          )
            ligne = "Réglementation : Arrêté du 27 juin 2012 (ML française)";
          doc.text(ligne, 20, pas);
          pas = pas + 5;
        }
      } else {
        ligne = "";
        doc.text(ligne, 20, pas);
        pas = pas + 5;

        if (selectedEccn?.publicListName.includes("ITAR")) {
          ligne = String(i18n.t("global.usageitar"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;
        }

        if (selectedEccn?.publicListName.includes("EAR")) {
          ligne = String(i18n.t("global.usageEAR1"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;

          ligne = String(i18n.t("global.usageEAR2"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;
        }

        if (selectedEccn?.code == "EAR99") {
          ligne = String(i18n.t("global.usageEAR1"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;

          ligne = String(i18n.t("global.usageEAR991"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;

          ligne = String(i18n.t("global.usageEAR992"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;
        }

        ligne = "";
        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;
      }

      ligne = " ";
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      ligne =
        i18n.t("pages.exportability.search-ECCN") +
        " : " +
        String(selectedEccn?.code); //classement
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      ligne = " ";
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      if (
        (country === "fr-FR" || country === "fr") &&
        props.rootStore.eclabsStore.countryCode !== "UK" &&
        props.rootStore.eclabsStore.countryCode !== "US" &&
        props.rootStore.eclabsStore.countryCode !== "NO" &&
        props.rootStore.eclabsStore.countryCode !== "EU" &&
        props.rootStore.eclabsStore.countryCode !== "SE" &&
        props.rootStore.eclabsStore.countryCode !== "NL" &&
        props.rootStore.eclabsStore.countryCode !== "ZA" &&
        props.rootStore.eclabsStore.countryCode !== "KR"
      ) {
        ligne = "";
        if (eccns_parent.length != 0)
          if (selectedEccn?.code === eccns_parent[0].code) {
            ligne = String(selectedEccn?.description);
            ligne = ligne.replace("μ", "micro");

            doc.text(ligne, 20, pas, { maxWidth: 180 });
            pas = pas + 5;
          } else {
            ligne =
              String(eccns_parent[0]?.description) +
              "\n" +
              String(selectedEccn?.description);
            ligne = ligne.replace("μ", "micro");
            doc.text(ligne, 20, pas, { maxWidth: 180 });
            pas = pas + 5;
          }
        ligne = " ";

        if (selectedEccn?.notes !== undefined) ligne = selectedEccn?.notes;
        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;

        ligne = "";
        if (selectedEccn?.tech_notes !== undefined)
          ligne = selectedEccn?.tech_notes;
        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;
      } else {
        // if (selectedEccn?.description_en!==undefined)  ligne[6]=String(selectedEccn?.description_en)

        if (selectedEccn?.code === eccns_parent[0].code)
          ligne = selectedEccn?.isUsOrigin
            ? String(selectedEccn?.description)
            : String(selectedEccn?.description_en);
        else
          ligne =
            (selectedEccn?.isUsOrigin
              ? eccns_parent[0]?.description
              : eccns_parent[0]?.description_en) +
            "\n" +
            (selectedEccn?.isUsOrigin
              ? String(selectedEccn?.description)
              : String(selectedEccn?.description_en));

        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;

        ligne = " ";

        if (selectedEccn?.notes_en !== undefined)
          ligne = selectedEccn?.notes_en;
        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;
        ligne = "";
        if (selectedEccn?.tech_notes_en !== undefined)
          ligne = selectedEccn?.tech_notes_en;
        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;
      }
      if (selectedEccnus && props.rootStore.exportabilityStore.usOrigin) {
        //********us ******************************* */
        ligne = " ";
        doc.text(ligne, 20, pas);
        pas = pas + 5;

        ligne = " ";
        doc.text(ligne, 20, pas);
        pas = pas + 5;

        doc.setFont("", "bold");
        ligne = "U.S. classification";
        doc.text(ligne, 20, pas);
        pas = pas + 5;
        doc.setFont("", "normal");

        ligne = "";
        doc.text(ligne, 20, pas);
        pas = pas + 5;

        if (selectedEccnus?.publicListName.includes("ITAR")) {
          ligne = String(i18n.t("global.usageitar"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;
        }

        if (selectedEccnus?.publicListName.includes("EAR")) {
          ligne = String(i18n.t("global.usageEAR1"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;

          ligne = String(i18n.t("global.usageEAR2"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;
        }

        if (selectedEccnus?.code == "EAR99") {
          ligne = String(i18n.t("global.usageEAR1"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;

          ligne = String(i18n.t("global.usageEAR991"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;

          ligne = String(i18n.t("global.usageEAR992"));
          doc.text(ligne, 20, pas);
          pas = pas + 5;
        }

        ligne = "";
        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;

        ligne =
          i18n.t("pages.exportability.search-ECCN-us") +
          " : " +
          String(selectedEccnus?.code); //classement
        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;

        ligne = " ";
        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;

        const codeus = selectedEccnus?.code.includes(".")
          ? selectedEccnus.code.split(".")[0]
          : selectedEccnus?.code;
        let eccns_parentus: Eccn[] = [];
        try {
          eccns_parentus = await api.deepSearchEccn(
            codeus,
            selectedEccnus?.publicListName!
          );
        } catch (error) {}

        if (eccns_parentus.length > 0)
          if (selectedEccnus?.code === eccns_parentus[0].code)
            ligne = String(selectedEccnus?.description);
          else
            ligne =
              eccns_parentus[0]?.description +
              "\n" +
              selectedEccnus?.description;
        //  ligne = String(selectedEccnus?.description);
        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;
      }
      //******************************************** */

      if (texte_libre) {
        ligne = " ";
        doc.text(ligne, 20, 10);
        pas = pas + 5;
        doc.setFont("", "bold");
        ligne = i18n.t("pages.exportability.Comment");

        var nbrLigne = 0;
        var lignes = textelibre.split("\n");
        for (i = 0; i < lignes.length; i++) {
          nbrLigne++;
          if (lignes[i].length > 129)
            nbrLigne = nbrLigne + Math.floor(lignes[i].length / 129);
        }

        var hdoc = doc.getTextWidth(textelibre);

        //  alert(hdoc)
        if (hdoc > 500) doc.addPage([210, nbrLigne * 10 + 30], "p");
        else doc.addPage();

        doc.text(ligne, 20, 10);
        pas = pas + 5;
        doc.setFont("", "normal");

        doc.rect(15, 15, 190, nbrLigne * 7, "S");

        ligne = " ";
        doc.text(ligne, 20, 10);
        pas = pas + 5;

        ligne = String(textelibre);
        doc.text(ligne, 20, 20, { maxWidth: 180 });
        pas = pas + 5;
      }
    } else {
      ligne = i18n.t("global.usagenc1");
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      ligne = i18n.t("global.usagenc2");
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      ligne = i18n.t("global.usagenc3");
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      ligne = "";
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      if (selectedEccn?.code == "NC") codenc = "Not Listed";
      ligne =
        i18n.t("pages.exportability.search-ECCN") + " : " + String(codenc); //classement
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      ligne = "";
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      if (
        country === "fr-FR" ||
        country === "fr" ||
        props.rootStore.eclabsStore.countryCode === "UK" ||
        props.rootStore.eclabsStore.countryCode === "US" ||
        props.rootStore.eclabsStore.countryCode === "NO" ||
        props.rootStore.eclabsStore.countryCode === "EU" ||
        props.rootStore.eclabsStore.countryCode === "SE"
      ) {
        const codeus1 = selectedEccn?.code.includes(".")
          ? selectedEccn.code.split(".")[0]
          : selectedEccn?.code;
        let eccns_parentus1: Eccn[] = [];
        try {
          eccns_parentus1 = await api.deepSearchEccn(
            codeus1!,
            selectedEccn?.publicListName!
          );
        } catch (error) {}
        if (eccns_parentus1.length > 0)
          if (selectedEccn?.code === eccns_parentus1[0].code)
            ligne = String(selectedEccn?.description);
          else
            ligne =
              eccns_parentus1[0]?.description +
              "\n" +
              selectedEccn?.description;

        //ligne = String(selectedEccn?.description);
        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;

        //ligne[7]=String(selectedEccn?.description)
      } else {
        ligne = String(selectedEccn?.description_en);
        doc.text(ligne, 20, pas, { maxWidth: 180 });
        pas = pas + 5;
      }

      //********us ******************************* */
      ligne = " ";
      doc.text(ligne, 20, pas);
      pas = pas + 5;
      ligne = " ";
      doc.text(ligne, 20, pas);
      pas = pas + 5;
      ligne = " ";
      doc.text(ligne, 20, pas);
      pas = pas + 5;
      ligne = " ";
      doc.text(ligne, 20, pas);
      pas = pas + 5;
      ligne = " ";
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      doc.setFont("", "bold");
      ligne = "U.S. classification";
      doc.text(ligne, 20, pas);
      pas = pas + 5;
      doc.setFont("", "normal");

      ligne = " ";
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      if (selectedEccnus?.code == "EAR99") {
        ligne = String(i18n.t("global.usageEAR1"));
        doc.text(ligne, 20, pas);
        pas = pas + 5;

        ligne = String(i18n.t("global.usageEAR991"));
        doc.text(ligne, 20, pas);
        pas = pas + 5;

        ligne = String(i18n.t("global.usageEAR992"));
        doc.text(ligne, 20, pas);
        pas = pas + 5;
      }

      ligne = "";
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      ligne =
        i18n.t("pages.exportability.search-ECCN-us") +
        " : " +
        String(selectedEccnus?.code); //classement
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      ligne = " ";
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      const codeus1 = selectedEccnus?.code.includes(".")
        ? selectedEccnus.code.split(".")[0]
        : selectedEccnus?.code;

      let eccns_parentus1: Eccn[] = [];
      try {
        let eccns_parentus1 = await api.deepSearchEccn(
          codeus1!,
          selectedEccnus?.publicListName!
        );
      } catch (error) {}

      if (eccns_parentus1.length > 0)
        if (selectedEccnus?.code === eccns_parentus1[0].code)
          ligne = String(selectedEccnus?.description);
        else
          ligne =
            eccns_parentus1[0]?.description +
            "\n" +
            selectedEccnus?.description;

      // ligne = String(selectedEccnus?.description);
      doc.text(ligne, 20, pas, { maxWidth: 180 });
      pas = pas + 5;

      //******************************************** */

      ligne = " ";
      doc.text(ligne, 20, pas);
      pas = pas + 5;

      if (texte_libre) {
        ligne = " ";
        doc.text(ligne, 20, 10);
        pas = pas + 5;
        doc.setFont("", "bold");
        ligne = i18n.t("pages.exportability.Comment");

        var nbrLigne = 0;
        var lignes = textelibre.split("\n");
        for (i = 0; i < lignes.length; i++) {
          nbrLigne++;
          if (lignes[i].length > 129)
            nbrLigne = nbrLigne + Math.floor(lignes[i].length / 129);
        }

        var hdoc = doc.getTextWidth(textelibre);

        //  alert(hdoc)
        if (hdoc > 500) doc.addPage([210, nbrLigne * 10 + 30], "p");
        else doc.addPage();

        doc.text(ligne, 20, 10);
        pas = pas + 5;
        doc.setFont("", "normal");
        doc.rect(15, 45, 190, nbrLigne * 7, "S");

        ligne = " ";
        doc.text(ligne, 20, 10);
        pas = pas + 5;

        ligne = String(textelibre);
        doc.text(ligne, 20, 50, { maxWidth: 180 });
        pas = pas + 5;
      }
    }

    let height = doc.internal.pageSize.getHeight();
    //doc.text( ligne,20,80,{ maxWidth: 180 })

    if (all_liste) {
      let element = document.getElementById("print")!;
      let canvas = await html2canvas(element);
      let data = canvas.toDataURL("image/jpg", 0.35);

      var margin = 10;
      var depart = 210;
      //if (props.rootStore.eclabsStore.searchType === "CHECKLIST")
      //  depart = 110;
      var imgWidth = depart - 2 * margin;

      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      // var doc = new jsPDF('p', 'mm');
      var position = 20;

      if (imgHeight + 2 * margin < 290)
        doc.addPage([imgWidth + 2 * margin, 297]);
      else doc.addPage([imgWidth + 2 * margin, imgHeight + 2 * margin]);

      doc.addImage(data, "PNG", margin, margin, imgWidth, imgHeight);
      /* 
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(data, 'PNG', margin, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }
  */
      doc.text(
        i18n.t("global.footer"),
        doc.internal.pageSize.getWidth() / 2 - 30,
        heightLeft + 30
      );
    }

    //else

    // doc.text( i18n.t('global.footer'),doc.internal.pageSize.getWidth()/2-30,295)

    doc.save(afDate + " Classification " + selectedEccn?.code! + ".pdf");

    const link = document.createElement("a");
    // link.href = data;
    // link.download = 'downloaded-image.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setOpen(false);
    setOpen1(false);
  };

  return (
    <RightPanel display={props.isOpen} onClose={() => props.onClose()}>
      <Title tag="h2">{i18n.t("components.searchEccn.title")}</Title>

      <RadioGroup
        aria-label="search-type"
        name="search-type"
        className={styles.radioButtons}
        value={searchTypeFromStore}
        onChange={(e) => {
          setGo(false);
          handleChangeSearchType(e.target.value);
        }}
      >
        <FormControlLabel
          id="keyword-button"
          value="KEYWORD"
          control={<Radio color="primary" />}
          label={i18n.t("pages.exportability.Search-keyword")}
          onChange={() => {
            handleChangeInputSearch({ target: { value: "" } });
            setPrevSearchDeepSearch("");
            setSearchInput("");
            setIntangible(false),
              props.rootStore.eclabsStore.setIntangile(false);
          }}
        />

        <FormControlLabel
          id="sap-button"
          value="PARTNUMBER"
          control={<Radio color="primary" />}
          label={i18n.t("pages.exportability.Search-number")}
          onChange={() => {
            handleChangeInputSearch({ target: { value: "" } });
            setSearchInput("");
            setPrevSearchDeepSearch("");
            setUsOrigin(true);
            props.rootStore.exportabilityStore.usOrigin = true;
            setIntangible(false),
              props.rootStore.eclabsStore.setIntangile(false);
          }}
        />

        {wizard == true && (
          <FormControlLabel
            id="sap-button"
            value="CHECKLIST"
            control={<Radio color="primary" />}
            label={i18n.t("pages.exportability.Search-Checklist")}
            onChange={() => {
              handleChangeInputSearch({ target: { value: "" } });
              setSearchInput("");
              setPrevSearchDeepSearch("");
              setIntangible(false),
                props.rootStore.eclabsStore.setIntangile(false);
            }}
          />
        )}
        <span style={{ marginLeft: "10px", paddingTop: "8px" }}>
          {!wizard && props.rootStore.eclabsStore.countryCode === "FR" && (
            <Checkbox
              large
              id="checkbox"
              label="Import"
              checked={checkbox}
              onChange={() => {
                setCheckbox(!checkbox),
                  props.rootStore.eclabsStore.setIntangile(false);
                setIntangible(false);
              }}
            />
          )}
        </span>
        <span style={{ marginLeft: "10px", paddingTop: "8px" }}>
          <Checkbox
            id="checkboxIntangible"
            large={true}
            label="Intangible"
            checked={intangible}
            onChange={(e) => {
              props.rootStore.eclabsStore.setIntangile(!intangible);
              setIntangible(!intangible);
            }}
          />
        </span>
      </RadioGroup>

      <div className={styles.divider}></div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            //p: 1,
            //m: 1,
            bgcolor: "background.paper",
            borderRadius: 1,
            //justifyContent: "space-evenly",
          }}
        >
          {props.rootStore.eclabsStore.searchType === "KEYWORD" ? (
            <React.Fragment>
              <InputMUI
                sx={{
                  borderRadius: 0,
                  width: "40%",
                  fontSize: "14px",
                }}
                fullWidth
                id="search"
                value={searchInputLocal || ""}
                onKeyDown={(e) => handleKeyPress(e)}
                onChange={(e) => handleChangeInputSearch(e)}
                placeholder={i18n.t("components.searchEccn.placeholder")}
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton aria-label="recherche">
                      <Magnifier fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Effacer le contenu"
                      onClick={() => {
                        props.rootStore.eclabsStore.search = "";
                        handleChangeInputSearch({ target: { value: "" } });
                        setSearchInput("");
                      }}
                    >
                      <CrossIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </React.Fragment>
          ) : (
            <InputMUI
              sx={{ borderRadius: 0, width: "40%" }}
              fullWidth
              id="search"
              value={searchInputLocal || ""}
              onKeyDown={(e) => handleKeyPress(e)}
              onChange={(e) => handleChangeInputSearch(e)}
              placeholder={
                wizard
                  ? " "
                  : i18n.t("pages.new-flow.product.equipment.part-number")
              }
              size="small"
              startAdornment={
                <InputAdornment position="start">
                  <IconButton aria-label="recherche">
                    <Magnifier fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
          <div>
            <label className={styles.label}>
              {i18n.t("components.searchEccn.departure")}
            </label>
            <select
              className={styles.select}
              value={props.rootStore.eclabsStore.countryCode ? countryCode : ""}
              onChange={(e) => {
                setCountryCode(e.target.value as EccnCountry);
                props.rootStore.eclabsStore.setCountryCode(
                  e.target.value as EccnCountry
                );

                if (e.target.value == "US") setUsOrigin(true);
                props.rootStore.exportabilityStore.usOrigin = true;

                if (
                  props.rootStore.eclabsStore.searchType === "PARTNUMBER" ||
                  props.rootStore.eclabsStore.searchType === "CHECKLIST"
                ) {
                  setUsOrigin(true);
                  props.rootStore.exportabilityStore.usOrigin = true;
                }
              }}
            >
              {props.rootStore.eclabsStore.searchType === "PARTNUMBER" && (
                <option value="ALL">{i18n.t("global.all")}</option>
              )}

              {generateSelectOption(countries)}
            </select>
          </div>
          {/*  { props.rootStore.eclabsStore.searchType === 'KEYWORD' ? (
            <div>
              
              <label className={ styles.label }>{ i18n.t('components.searchEccn.item-type') }</label>
              <select
                className={ styles.select }
                value={ props.rootStore.eclabsStore.itemType || '' }
                onChange={ e => props.rootStore.eclabsStore.setItemType(e.target.value as ItemType) }>
                {
                  generateSelectOption(itemType)
                }
              </select>

              
            </div>
          ) : (*/}
          <Button
            key="search"
            id="search"
            className={styles.searchButton}
            onClick={() => {
              setGo(false);
              launchSearch();
              setTimeout(
                () => {
                  setGo(true); // Met setGo à true après 3 secondes.
                },
                props.rootStore.eclabsStore.searchType === "KEYWORD"
                  ? 3000
                  : 6000
              );
            }}
            size="large"
            disabled={
              searchInputLocal.length === 0 &&
              props.rootStore.eclabsStore.searchType !== "CHECKLIST"
            }
            primary
          >
            {i18n.t("components.searchEccn.search-button")}
          </Button>
          <div style={{ marginLeft: "10px" }}>
            {prevSearchDeepSearch && (
              <Button
                type="button"
                id="prev"
                size="large"
                className={styles.button_prev}
                onClick={() => prevFromDeep()}
              >
                {i18n.t("global.previous")}
              </Button>
            )}
          </div>

          {props.rootStore.eclabsStore.searchType === "CHECKLIST" && (
            <div style={{ marginLeft: "140px" }}>
              <Button
                type="button"
                id="prev"
                size="large"
                className={styles.button_prev}
                onClick={() => {
                  reset();

                  props.rootStore.eclabsStore.eccns = [];
                }}
              >
                Reset
              </Button>
            </div>
          )}

          {/* )} */}
        </Box>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            position: "fixed",
            inset: "70px 100px 150px 50px",
            backgroundColor: "#ffffff",
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="modal-modal-title">
          {i18n.t("global.titre")}
        </DialogTitle>
        <DialogContent>
          <TextField
            label={i18n.t("global.textrapport")}
            placeholder={i18n.t("global.holdtext")}
            multiline
            rows={10}
            fullWidth
            variant="outlined"
            value={textlibre}
            onChange={(e) => setTextlibre(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="exportpdf"
            size="small"
            primary
            type="button"
            className={styles.button}
            disabled={selectedEccn === undefined}
            onClick={() => handleDownloadImage()}
          >
            {i18n.t("global.pdf_clas")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open1}
        onClose={() => setOpen1(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="sm" // Ajustez la largeur au besoin
        fullWidth
      >
        <DialogTitle id="modal-modal-title">
          {i18n.t("components.searchEccn.titre_modal")}
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <Title tag="h5" className={styles.pStyles}>
              {i18n.t("components.searchEccn.titre_modal")}
            </Title>
            <Checkbox
              className={styles.checkbox}
              large={true}
              label={i18n.t("components.searchEccn.titreoption")}
              checked={titre_modal}
              onChange={(e) => {
                setTitreModal(e.target.checked);
              }}
            />
            {titre_modal && (
              <div>
                <TextArea
                  id="customer-company"
                  value={titre_modal_text || ""}
                  onChange={(e) => setTitreModalText(e.target.value)}
                />
              </div>
            )}
            {!titre_modal && <br />}
            <Checkbox
              className={styles.checkbox}
              large={true}
              label={i18n.t("components.searchEccn.all_liste")}
              checked={all_liste}
              onChange={(e) => {
                setAllListe(e.target.checked);
              }}
            />

            <br />

            <Checkbox
              className={styles.checkbox}
              large={true}
              label={i18n.t("components.searchEccn.texte_libre")}
              checked={texte_libre}
              onChange={(e) => {
                setTexteLibre(e.target.checked);
              }}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            id="exportpdf"
            size="small"
            primary
            type="button"
            className={styles.button}
            disabled={selectedEccn === undefined}
            onClick={() =>
              texte_libre ? setOpen(true) : handleDownloadImage()
            }
          >
            {i18n.t("global.pdf_clas")}
          </Button>

          <Button
            id="exportpdf1"
            size="small"
            primary
            type="button"
            className={styles.button}
            disabled={selectedEccn === undefined}
            onClick={() => setOpen1(false)}
          >
            {i18n.t("global.pdf_clas1")}
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ margin: "0px 800px" }}>
        {props.rootStore.eclabsStore.searchType !== "CHECKLIST" &&
        props.rootStore.eclabsStore.searchType !== "PARTNUMBER" &&
        (props.ouClick == "" || props.ouClick == "home") &&
        props.rootStore.eclabsStore.countryCode !== "US" &&
        !wizard ? (
          <Checkbox
            large
            label="US origin"
            checked={
              /*props.rootStore.exportabilityStore.usOrigin || */ usOrigin
            }
            onChange={() => {
              let choix = usOrigin;
              setUsOrigin(!choix);

              props.rootStore.exportabilityStore.setUsOrigin(!choix);
            }}
          />
        ) : (
          <br />
        )}
      </div>

      {props.rootStore.eclabsStore.loading && <Loading />}

      {go &&
        props.rootStore.eclabsStore.eccns.length === 0 &&
        props.rootStore.eclabsStore.loading === false && (
          <p id="empty-eccn" className={styles.emptyECCN}>
            <b>
              {props.rootStore.eclabsStore.searchType === "KEYWORD"
                ? i18n.t("errors.eccn-empty")
                : props.rootStore.eclabsStore.searchType !== "CHECKLIST"
                  ? props.rootStore.eclabsStore.emptycustom == ""
                    ? i18n.t("errors.eccn-empty-ref")
                    : i18n.t("errors.eccn-empty-custom") +
                      " " +
                      props.rootStore.eclabsStore.emptycustom
                  : i18n.t("errors.eccn-empty-refch")}
            </b>
          </p>
        )}

      {renderEccn()}
     
      <div className={styles.footer} ref={resultRef}>
        <div style={{ marginRight: "auto" }}>
          <Button
            id="exportpdf"
            size="large"
            primary
            type="button"
            className={styles.button}
            disabled={true}
            // onClick={() => {} }
          >
            {i18n.t("components.searchEccn.erp")}
          </Button>
        </div>

        <div style={{ marginLeft: "10px" }}>
          <Button
            id="exportability"
            size="large"
            primary
            type="button"
            className={styles.button}
            disabled={selectedEccn === undefined || selectedEccn?.eu_check  }
            onClick={() => {
            //props.rootStore.eclabsStore.eccns[1].code
              selectEccn();
            }}
          >
            
            {props.exportabilityMode
              ? checkbox
                ? "Import"
                : i18n.t("global.exportability")
              : i18n.t("components.searchEccn.select-eccn")}
          </Button>
        </div>

        <div style={{ marginLeft: "10px" }}>
          <Button
            id="exportpdf"
            size="large"
            primary
            type="button"
            className={styles.button}
            disabled={selectedEccn === undefined}
            onClick={() => setOpen1(true)}
          >
            {i18n.t("global.pdf_clas")}
          </Button>
        </div>
      </div>
    </RightPanel>
  );
};

export default inject("rootStore")(observer(SearchEccn));
