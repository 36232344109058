import React, { useState, useEffect } from "react";
import {
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  styled,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import RootStore from "../../../stores/rootStore";
import axios from "axios";
import i18n from "i18next";
import configuration from "../../../configuration";

interface FilesProps {
  rootStore: RootStore;
  currentLicense: any;
}

const Files: React.FC<FilesProps> = ({ rootStore, currentLicense }) => {
  const [cufFiles, setCufFiles] = useState<any[]>([]);
  const [contractFiles, setContractFiles] = useState<any[]>([]);
  const [productFiles, setProductFiles] = useState<any[]>([]);

  const deleteFile = async (id: number, slug: string) => {
    const url = `${configuration.LARAVEL_API}/resources/documents/${id}`;

    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Mettre à jour l'état après suppression selon le type de fichier
      if (slug === "license_cuf") {
        setCufFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
      }
      if (slug === "license_contract") {
        setContractFiles((prevFiles) =>
          prevFiles.filter((file) => file.id !== id)
        );
      }
      if (slug === "license_product") {
        setProductFiles((prevFiles) =>
          prevFiles.filter((file) => file.id !== id)
        );
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du fichier : ", error);
    }
  };

  async function handleCufFile(
    file: React.ChangeEvent<HTMLInputElement>,
    slug: string
  ) {
    const url = `${process.env.REACT_APP_SCREEN}/uploadFile`;
    const params = {
      tenantId: rootStore.userStore.user?.tenantId,
      slug: slug,
      categorie: "licence",
      typeId: currentLicense.id,
    };
    const formData = new FormData();
    formData.append("file", file.target.files?.[0] as Blob);
    const fileinfo = await axios.post(url, formData, {
      params,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (slug === "license_cuf") {
      setCufFiles((prevFiles) => [...prevFiles, fileinfo.data.data]);
    }
    if (slug === "license_contract") {
      setContractFiles((prevFiles) => [...prevFiles, fileinfo.data.data]);
    }
    if (slug === "license_product") {
      setProductFiles((prevFiles) => [...prevFiles, fileinfo.data.data]);
    }
  }

  function base64ToBlob(base64: string, mimeType: string): Blob | void {
    if (!base64) {
      console.error("Base64 string is undefined or empty");
      return;
    }
    base64 = base64.replace(/\s+/g, "").replace(/[^A-Za-z0-9+/=]/g, "");
    const padding = "=".repeat((4 - (base64.length % 4)) % 4);
    base64 += padding;

    try {
      const byteChars = atob(base64);
      const byteNumbers = new Array(byteChars.length);
      for (let i = 0; i < byteChars.length; i++) {
        byteNumbers[i] = byteChars.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mimeType });
    } catch (error) {
      console.error("Error converting base64 to blob:", error);
    }
  }

  const openPdfInNewTab = (file?: any) => {
    if (file) {
      const fileBlob = base64ToBlob(file.b64, "application/pdf");
      if (fileBlob) {
        const fileUrl = URL.createObjectURL(fileBlob);
        window.open(fileUrl, "_blank");
        setTimeout(() => {
          URL.revokeObjectURL(fileUrl);
        }, 1000);
      }
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  useEffect(() => {
    if (currentLicense?.documents) {
      const sortFilesByDate = (slug: string) =>
        currentLicense.documents
          .filter((item: any) => item.slug === slug)
          .sort(
            (a: any, b: any) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          );

      setCufFiles(sortFilesByDate("license_cuf"));
      setContractFiles(sortFilesByDate("license_contract"));
      setProductFiles(sortFilesByDate("license_product"));
    }
  }, [currentLicense]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-end"
      spacing={0.5}
      sx={{ width: "40%" }}
    >
      {currentLicense?.mention_cuf && (
        <Box sx={{ mb: 3 }}>
          <Typography>
            <b>
              Certificat d'utilisation finale / Engagement de non réexportation
              : {currentLicense.mention_cuf}
            </b>
          </Typography>
        </Box>
      )}

      {/* Certificat d'utilisation finale / Engagement de non réexportation */}
      <Box
        sx={{
          borderBottom: "1px solid #000",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "4px",
        }}
      >
        <Typography variant="body1">
          {i18n.t("pages.license.add.endUserCertificate")}
        </Typography>
        <IconButton component="label">
          <Add
            sx={{
              cursor: "pointer",
              ":hover": { color: "green" },
            }}
          />
          <VisuallyHiddenInput
            type="file"
            accept="application/pdf"
            onChange={(e) => handleCufFile(e, "license_cuf")}
          />
        </IconButton>
      </Box>

      {cufFiles.map((file) => (
        <Stack
          key={file.id}
          direction="row"
          sx={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <Button
            variant="text"
            onClick={() => openPdfInNewTab(file)}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "350px",
            }}
          >
            {file.filename}
          </Button>
          <Delete
            onClick={() => deleteFile(file.id, "license_cuf")}
            sx={{
              color: "red",
              verticalAlign: "bottom",
              cursor: "pointer",
              ":hover": { color: "black" },
            }}
          />
        </Stack>
      ))}

      {/* Commande ou contrat */}
      <Box
        sx={{
          borderBottom: "1px solid #000",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "4px",
        }}
      >
        {i18n.t("pages.license.add.orderContract")}
        <IconButton component="label">
          <Add
            sx={{
              cursor: "pointer",
              ":hover": { color: "green" },
            }}
          />
          <VisuallyHiddenInput
            type="file"
            accept="application/pdf"
            onChange={(e) => handleCufFile(e, "license_contract")}
          />
        </IconButton>
      </Box>

      {contractFiles.map((file) => (
        <Stack
          key={file.id}
          direction="row"
          sx={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <Button
            variant="text"
            onClick={() => openPdfInNewTab(file)}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "350px",
            }}
          >
            {file.filename}
          </Button>
          <Delete
            onClick={() => deleteFile(file.id, "license_contract")}
            sx={{
              color: "red",
              verticalAlign: "bottom",
              cursor: "pointer",
              ":hover": { color: "black" },
            }}
          />
        </Stack>
      ))}

      {/* Fiche produit */}
      <Box
        sx={{
          borderBottom: "1px solid #000",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "4px",
        }}
      >
        {i18n.t("pages.license.add.productInformation")}
        <IconButton component="label">
          <Add
            sx={{
              cursor: "pointer",
              ":hover": { color: "green" },
            }}
          />
          <VisuallyHiddenInput
            type="file"
            accept="application/pdf"
            onChange={(e) => handleCufFile(e, "license_product")}
          />
        </IconButton>
      </Box>

      {productFiles.map((file) => (
        <Stack
          key={file.id}
          direction="row"
          sx={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <Button
            variant="text"
            onClick={() => openPdfInNewTab(file)}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "350px",
            }}
          >
            {file.filename}
          </Button>
          <Delete
            onClick={() => deleteFile(file.id, "license_product")}
            sx={{
              color: "red",
              verticalAlign: "bottom",
              cursor: "pointer",
              ":hover": { color: "black" },
            }}
          />
        </Stack>
      ))}
    </Stack>
  );
};

export default Files;
