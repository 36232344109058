/* Styles import */
import styles from "./tenant.module.css";

/* Global import */
import React, { Component, useEffect, useState } from "react";

/* Custom import */
import { getToLocaleDate } from "../../utils/date";
import { Hidden, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteSweep";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import {
  IconButton,
  //DialogActions,
  //DialogContent,
  //Dialog,
  TextInput,
  Button,
  GridRow,
  GridCol,
} from "../../components/horizon-components-react/src/components";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import i18n from "i18next";
import { observer, inject } from "mobx-react";
import UploadInput from "../uploadInput";
import { useNavigate } from "react-router-dom";

interface Props {
  id?: number;
  name: string;
  homepageUploadDate?: string;
  homepageFilename?: string;
  erp?: string;
  wizard?: string;
  screening?: string;
  productLineUploadDate?: string;
  productLineFilename?: string;
  rootStore: import("../../stores/rootStore").default;
}

interface InjectedProps extends Props {
  rootStore: import("../../stores/rootStore").default;
}
interface State {
  name: string;
  homepage?: File;
  erp: boolean;
  wizard: boolean;
  screening: string;
  ah: string;
  productline?: File;
  open: boolean;
  open1: boolean;
  desId: number;
}

const Tenant: React.FC<Props> = (props) => {
  const [state, setState] = useState({
    name: "",
    erp: false,
    wizard: false,
    search_type :1,
    screening: "USE_BUILT_IN_SCREENING",
    ah: "NO",
    open: false,
    open1: false,
    desId: 0,
    homepage: {} as File,
    productline: {} as File,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SCREEN}/getTenantAh?name=` +
            encodeURIComponent(`${props.name}`)
        );
        const ok = await response.json();

        setState({
          ...state,
          ah: ok.ah,
          wizard: ok.wizard == 1,
          search_type :parseInt(ok.search_type, 10),
          homepage: {} as File,
          erp: props.erp === "true",

          screening: props.screening || "USE_BVD",
          productline: {} as File,
        });
      } catch (error) {
        // Gérer les erreurs ici si nécessaire
        console.error(
          "Une erreur s'est produite lors de la récupération des données :",
          error
        );
      }
    };

    fetchData(); // Appel de la fonction fetchData au montage du composant

    // Si vous avez des dépendances, ajoutez-les ici pour exécuter le useEffect à nouveau
  }, [props.name]);

  const submitDelete = async (userId: string) => {
    // alert('suppression');
    await props.rootStore.userStore.submitDeleteTenant(userId);
  };

  const submitDesactive = async (userId: string) => {
    //const { firstname, lastname, email } = state
    //alert('Désactivation');
    await props.rootStore.userStore.submitDesactiveTenant(userId);

    // setState({ firstname: '', lastname: '', email: '' })
  };

  const handleupdate = async (
    maah: string,
    merp: string,
   
    mwizard: string,
     msearch_type:number,
    mscreening: string
  ) => {
    const { erp, ah, wizard, screening ,search_type } = state;

    fetch(
      `${process.env.REACT_APP_SCREEN}/updateTenantAh?name=` +
        encodeURIComponent(`${props.name}`) +
        `&screening=` +
        encodeURIComponent(`${mscreening}`) +
        `&wizard=` +
        encodeURIComponent(`${mwizard}`) +
          `&search_type=` +
        encodeURIComponent(`${msearch_type}`) +
        `&ah=` +
        encodeURIComponent(`${maah}`) +
        `&erp=` +
        encodeURIComponent(`${merp}`)
    )
      .then((response) => {
        return response.json();
      })
      .then((ok) => {});
    props.rootStore.notificationStore.setNotification(
      i18n.t("pages.admin.users.updated.title"),
      i18n.t("pages.admin.users.updated.message"),
      "success"
    );
  };

  const handleSubmit = () => {
    const { homepage, erp, screening, productline } = state;

    props.rootStore.tenantStore.submitTenant(
      {
        homepage,
        name: props.name,
        erp,
        screening,
        productline,
      },
      "PUT"
    );
  };

  const handleOpen = () => setState({ ...state, open: true });
  const handleClose = () => setState({ ...state, open: false });
  const handleOpen1 = () => setState({ ...state, open1: true });
  const handleClose1 = () => setState({ ...state, open1: false });

  return (
    <tr style={{ border: "1px solid black"}}>
      <th scope="row" style={{ width: "15%" }}>
        <b> {props.name || ""}</b>
      </th>
       <td style={{ width: "10%" }}>
        <Button
          onClick={
            () => navigate("entite?parametre=" + props.name) /*(
              (window.location.href =
                "/ui/admin/entite?parametre=" + props.name)
            )*/
          }
        >
          {i18n.t("pages.admin.tenants.ajout")}
        </Button>
      </td>
      <td style={{ width: "15%" }}>
        <Button
          onClick={
            () => navigate("entite_templete?parametre=" + props.name) 
          }
        >
          Part numbers 
        </Button>
      </td>
     
      <td style={{ width: "10%" }}>
        <select
          id="erp"
          disabled={
            !props.rootStore.userStore.isAuthorized(["SUPER_ADMINISTRATOR"])
          }
          value={state.erp.toString() || ""}
          onChange={(e) => {
            e.target.value == "true"
              ? setState({ ...state, erp: true })
              : setState({ ...state, erp: false });
            handleupdate(
              state.ah,
              e.target.value,
              state.wizard.toString(),
              state.search_type,
              state.screening
            );
          }}
        >
          <option value="true">
            {i18n.t("pages.admin.tenants.table.erp.true-value")}
          </option>
          <option value="false">
            {i18n.t("pages.admin.tenants.table.erp.false-value")}
          </option>
        </select>
      </td>
       <td style={{ border: "0px solid black", width: "15%" }}>
        <select
          id="search_type"
          disabled={
            !props.rootStore.userStore.isAuthorized(["SUPER_ADMINISTRATOR"])
          }
          value={state.search_type}
          onChange={(e) => {
           setState({ ...state, search_type:parseInt(e.target.value, 10)});
              
            handleupdate(
              state.ah,
              state.erp.toString(),
              state.wizard.toString(),
             parseInt(e.target.value, 10),
              state.screening
            );
          }}
        >
          <option value="1">Keyword</option>
          <option value="2">Part number</option>
          <option value="3">Decision tree</option>
        </select>
      </td>
      <td style={{ border: "0px solid black", width: "10%" }}>
        <select
          id="wizard"
          disabled={
            !props.rootStore.userStore.isAuthorized(["SUPER_ADMINISTRATOR"])
          }
          value={state.wizard.toString() || ""}
          onChange={(e) => {
            e.target.value == "true"
              ? setState({ ...state, wizard: true })
              : setState({ ...state, wizard: false });
            handleupdate(
              state.ah,
              state.erp.toString(),
              e.target.value,
              state.search_type,
              state.screening
            );
          }}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </td>
      <td style={{ border: "0px solid black", width: "15%" }}>
        <select
          id="screening"
          disabled={
            !props.rootStore.userStore.isAuthorized(["SUPER_ADMINISTRATOR"])
          }
          value={state.screening || ""}
          onChange={(e) => {
            setState({ ...state, screening: e.target.value });
            handleupdate(
              state.ah,
              state.erp.toString(),
              state.wizard.toString(),
               state.search_type,
              e.target.value
            );
          }}
        >
          <option value="USE_BUILT_IN_SCREENING">EC Compliance</option>
          <option value="USE_BVD">
            {i18n.t("pages.admin.tenants.table.screening.bvd-value")}
          </option>
        </select>
      </td>

      <td style={{ border: "0px solid black", width: "10%" }}>
        <select
          id="ah"
          value={state.ah || ""}
          onChange={(e) => {
            setState({ ...state, ah: e.target.value });
            handleupdate(
              e.target.value,
              state.erp.toString(),
              state.wizard.toString(),
               state.search_type,
              state.screening
            );
          }}
        >
          <option value="No">No </option>
          <option value="MEU">MEU</option>
        </select>
      </td>

      <td style={{ width: "5%" }}>
        <IconButton
          onClick={() => {
            if (props.id) setState({ ...state, desId: props.id });
            handleOpen1();
          }}
          style={{
            display: props.rootStore.userStore.isAuthorized([
              "SUPER_ADMINISTRATOR",
            ])
              ? "block"
              : "none",
          }}

          //   disabled={ !rootStore.userStore.isAuthorized([ 'SUPER_ADMINISTRATOR' ]) }
        >
          <Tooltip title="Supprimer">
            <DeleteIcon style={{ color: "#0085ad" }} />
          </Tooltip>
        </IconButton>

        <Dialog
          open={state.open1}
          title="Suppression tenant"
          onClose={handleClose1}
        >
          <DialogContent>
            Le client va etre supprimé ainsi que les données (flux et licences)
            de tous ses utilisateurs.
            <br />
            Etes-vous vraiment sûr ?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>Annuler</Button>
            <Button
              primary
              onClick={() => {
                submitDelete(props.name);
                handleClose1();
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </td>
      <td style={{ width: "5%" }}>
        <IconButton
          onClick={() => {
            if (props.id) setState({ ...state, desId: props.id });

            handleOpen();
          }}
          style={{
            display: props.rootStore.userStore.isAuthorized([
              "SUPER_ADMINISTRATOR",
            ])
              ? "block"
              : "none",
          }}

          //disabled={ !rootStore.userStore.isAuthorized([ 'SUPER_ADMINISTRATOR' ]) }
        >
          <Tooltip title="Désactiver">
            <DeleteOutlineIcon style={{ color: "#0085ad" }} />
          </Tooltip>
        </IconButton>

        <Dialog
          open={state.open}
          title="Désactivation tenant"
          onClose={handleClose}
        >
          <DialogContent>
            Le client va être désactivé : ses utilisateurs ne pourront plus se
            connecter, mais les données qu'ils ont créées seront conservées.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              primary
              onClick={() => {
                submitDesactive(props.name);
                handleClose();
              }}
            >
              Désactiver
            </Button>
          </DialogActions>
        </Dialog>
      </td>
    </tr>
  );
};

export default inject("rootStore")(observer(Tenant));
