/* Global import */
import { observable, action, computed, makeAutoObservable } from "mobx";
import i18n from "i18next";

/* Custom import */
import Api from "../utils/api";

export default class UserStore {
  rootStore: import("./rootStore").default;
  api: Api;

  @observable user?: User;

  @observable users: User[] = [];
  @observable sites: Site[] = [];
  @observable isValidUser: boolean = false;
  @observable routes: String[] = [];

  @observable loading: boolean = false;

  constructor(rootStore: import("./rootStore").default) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.api = new Api();
  }

  @action setUser(user: User) {
    this.user = user;
  }

  @action isAuthorized(roles?: UserRole[]): boolean {
    return (
      this.rootStore.authStore.isLogged &&
      this.user !== undefined &&
      (roles === undefined || roles.indexOf(this.user.role) !== -1)
    );
  }

  @action getScreeningType(): UserScreening | undefined {
    return this.user && this.user.screeningType;
  }

  /*
   * User administration
   */

  @computed get otherUsers(): User[] {
    return this.users.filter((user) => this.user && user.id !== this.user.id);
  }

  @action async getUsers() {
    this.loading = true;

    try {
      this.users = await this.api.getUsers();
    } catch (e) {}

    this.loading = false;
  }

  @action async getSites() {
    try {
      this.sites = await this.api.getSites();
    } catch (e) {}
  }

  @action async updateentite(userId: number, entite: string) {
    this.loading = true;

    try {
      const user = this.updateUser(
        userId,
        undefined,
        undefined,
        undefined,
        undefined,
        entite
      );
      if (user) {
        await this.api.updateuserentite(userId, entite);
        this.rootStore.notificationStore.setNotification(
          i18n.t("pages.admin.users.updated.title"),
          i18n.t("pages.admin.users.updated.message"),
          "success"
        );
      }
    } catch (e) {}

    this.loading = false;

    window.location.reload();
  }

  @action async updateRole(userId: number, role: UserRole) {
    this.loading = true;

    try {
      const user = this.updateUser(userId, role);
      //console.log(user)
      if (user) {
        await this.api.updateUser(user);
        this.rootStore.notificationStore.setNotification(
          i18n.t("pages.admin.users.updated.title"),
          i18n.t("pages.admin.users.updated.message"),
          "success"
        );
      }
    } catch (e) {}

    this.loading = false;
  }

  @action async updateExpiration_date(userId: number, expiration_date: string) {
    this.loading = true;

    try {
      const user = this.updateUser(
        userId,
        undefined,
        undefined,
        undefined,
        expiration_date
      );

      if (user) {
        await this.api.updateUser(user);
        this.rootStore.notificationStore.setNotification(
          i18n.t("pages.admin.users.updated.title"),
          i18n.t("pages.admin.users.updated.message"),
          "success"
        );
      }
    } catch (e) {}

    this.loading = false;
  }

  @action async updateTenant(userId: number, tenantId: string) {
    this.loading = true;

    try {
      const user = this.updateUser(userId, undefined, undefined, tenantId);

      if (user) {
        await this.api.updateUser(user);
        this.rootStore.notificationStore.setNotification(
          i18n.t("pages.admin.users.updated.title"),
          i18n.t("pages.admin.users.updated.message"),
          "success"
        );
      }
    } catch (e) {}

    this.loading = false;
  }

  @action async updateSite(userId: number, siteId: number) {
    this.loading = true;

    try {
      const user = this.updateUser(userId, undefined, siteId);

      if (user) {
        user.siteId = siteId;
        //  console.log(siteId)
        await this.api.updateuserlara(user.id, siteId);
        this.rootStore.notificationStore.setNotification(
          i18n.t("pages.admin.users.updated.title"),
          i18n.t("pages.admin.users.updated.message"),
          "success"
        );
      }
    } catch (e) {}

    this.loading = false;
  }

  @action async updateDeparture(userId: number, departure: string) {
    this.loading = true;

    try {
      const user = this.updateUser(
        userId,
        undefined,
        undefined,
        undefined,
        undefined,
        departure
      );

      if (user) {
        user.departure_country = departure;
        //  console.log(siteId)
        await this.api.updateuserlara1(user.id, departure);
        this.rootStore.notificationStore.setNotification(
          i18n.t("pages.admin.users.updated.title"),
          i18n.t("pages.admin.users.updated.message"),
          "success"
        );
      }
    } catch (e) {}

    this.loading = false;
  }

  @action updateUser(
    userId: number,
    userRole?: UserRole,
    siteId?: number,
    tenantId?: string,
    expiration_date?: string,
    departure_country?: string,
    entite?: string
  ): User | undefined {
    let currentUser: User | undefined = undefined;

    this.users = this.users.map((user) => {
      if (user.id === userId) {
        user.entite = entite || user.entite;
        user.role = userRole || user.role;
        user.siteId = siteId || user.siteId;
        user.tenantId = tenantId || user.tenantId;
        user.expiration_date = expiration_date || user.expiration_date;
        user.departure_country = departure_country || user.departure_country;

        currentUser = user;
      }

      return user;
    });

    return currentUser;
  }

  @action async confirmUser(
    userId: number,
    password: string,
    confirmPassword: string
  ) {
    this.loading = true;

    try {
      const authResponse = await this.api.activateUser(
        userId,
        password,
        confirmPassword
      );

      window.localStorage.setItem("token", authResponse.token);
      this.rootStore.authStore.setLogged(authResponse);
    } catch (e) {}

    this.loading = false;
  }

  @action async checkActiveUser(userId: number, token: string) {
    try {
      const user = await this.api.checkActivatedUser(userId, token);

      this.isValidUser = Boolean(user);
    } catch (e) {}
  }

  @action async addUser(
    firstname: string,
    lastname: string,
    AtenantId: string,
    email: string,
    expiration_date: string
  ) {
    this.loading = true;

    try {
      await this.api.addUser(
        firstname,
        lastname,
        AtenantId,
        email,
        expiration_date
      );
      this.rootStore.notificationStore.setNotification(
        i18n.t("pages.admin.users.saved.title"),
        i18n.t("pages.admin.users.saved.message"),
        "success"
      );
      window.location.reload();
    } catch (e) {}

    this.loading = false;
  }

  @action async submitDesactive(userId: number) {
    this.loading = true;

    try {
      await this.api.submitDesactive(userId);
      window.location.reload();
    } catch (e) {}

    this.loading = false;
  }

  @action async submitDesactiveTenant(userId: string) {
    this.loading = true;

    try {
      await this.api.submitDesactiveTenant(userId);
      window.location.reload();
    } catch (e) {}

    this.loading = false;
  }

  @action async submitDelete(userId: number) {
    this.loading = true;

    try {
      await this.api.submitDelete(userId);
      window.location.reload();
    } catch (e) {}

    this.loading = false;
  }
  @action async submitDeleteTenant(userId: string) {
    this.loading = true;

    try {
      await this.api.submitDeleteTenant(userId);
      window.location.reload();
    } catch (e) {}

    this.loading = false;
  }

  @action async getUserRoutes() {
    this.loading = true;

    if (this.user) {
      this.routes = await this.api.getUsersRoutes(this.user.id);
      return this.routes;
    }

    this.loading = false;
  }
}
