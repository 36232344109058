/* Styles import */
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  Loading,
  TextInput,
} from "../../components/horizon-components-react/src/components";
import i18n from "i18next";
import { inject, observer } from "mobx-react";

/* Global import */
import React, { useEffect, useState } from "react";
import styles from "./activate.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

interface Props {
  rootStore: import("../../stores/rootStore").default;
}

const RecuperationAccount: React.FC<Props> = ({ rootStore }) => {
  const navigate = useNavigate();

 const [userId, setUserId] = useState(0);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

 
  const [badPassword, setBadPassword] = useState(false);


    const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const id = queryParams.get('id');
  const token = queryParams.get('token');

  useEffect(() => {
    if (rootStore.authStore.isLogged) {
      navigate("/home");
    }
  }, [rootStore.authStore.isLogged, navigate]);

  useEffect(() => {
    setUserId( parseInt(id || "", 10) );
  }, [id]);



  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

//    const { userId, password, confirmPassword } = state;

    if (password && confirmPassword && password === confirmPassword) {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_SCREEN}/userspass?id=` +
              userId +
              "&token=" +
              token +
              "&password=" +
              password,
          )
          .then((response) => {
            // Gérer la réponse ici
          })
          .catch((error) => {
            console.error("Erreur :", error);
          });
        await rootStore.userStore.confirmUser(
          userId,
          password,
          confirmPassword,
        );
        setBadPassword( false );
        navigate("/home");
      } catch (error: any) {
        rootStore.authStore.setError(error);
      }
    } else {
      setBadPassword( true );
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.cardLogin}>
        <CardHeader title={i18n.t("pages.activate.title_forget")} />
        {badPassword && (
          <div className={styles.error}>
            <p>{i18n.t("errors.wrong-password")}</p>
          </div>
        )}
        <CardContent>
          <form id="activation-form" onSubmit={onSubmit}>
            <FormGroup>
              <TextInput
                id="password"
                type="password"
                label={i18n.t("pages.activate.password_forget")}
                value={password}
                required={true}
                errorText={i18n.t("errors.password-not-defined")}
                onChange={(e) =>
                  setPassword(e.target.value)
                }
              />
            </FormGroup>

            <FormGroup>
              <TextInput
                id="confirmPassword"
                type="password"
                label={i18n.t("pages.activate.confirm-password")}
                value={confirmPassword}
                required={true}
                errorText={i18n.t("errors.confirmPassword-not-defined")}
                onChange={(e) =>
                  setConfirmPassword( e.target.value )
                }
              />
            </FormGroup>

            {rootStore.authStore.loading ? (
              <Loading />
            ) : (
              <Button id="button" type="submit" primary>
                {i18n.t("pages.activate.submit")}
              </Button>
            )}
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default inject("rootStore")(observer(RecuperationAccount));
